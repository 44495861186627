import React from 'react';
import SanitizedHTML from 'react-sanitized-html';

import MyModal from './MyModal';


export default class Alerter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hasAlert: false,
            alert: {}
        }
    }

    componentDidMount(){
        this.getNextAlert();
    }

    getNextAlert = () => {
        fetch('https://app.notesforafter.com/operations/user/getNextAlert/', {
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                if(data.hasAlert==='yes'){
                    this.setState({alert: data.alert, hasAlert: true});
                }
            }
        })
        .catch((error)=>{
            console.log(error);
        })    
    }

    render(){
        if(!this.state.hasAlert){
            return (<div></div>)
        }

        return (
            <MyModal 
                autoOpen 
                modalHeaderText={this.state.alert.title} 
                onCloseModal={()=>this.setState({hasAlert: false})}
                >
                    <SanitizedHTML  
                        allowedTags={ [ 'h5', 'h4', 'h3', 'h2', 'h1', 'a', 'li', 'b', 'i', 'strong', 'br', 'div', 'img', 'p']} 
                        allowedAttributes={ { a: [ 'href', 'name', 'target' ], img: [ 'src' ]}}
                        selfClosing={ [ 'img', 'br']}
                        html={this.state.alert.content}
                    />
            </MyModal>
        )
    }
}