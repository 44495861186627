import React from 'react';
import {toast} from 'react-toastify';
import { NavLink } from 'react-router-dom';
import Spinner from 'components/Spinner';

export default class Accept extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            verified: false
        }
    }

    componentDidMount(){
        this.doAccept();
    }

    doAccept = () => {
            fetch('https://app.notesforafter.com/operations/invitations/accept/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    code: this.props.code,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({checked: true, verified: true, gatekeeper: data.gatekeeper, forUser: data.forUser});
                }else{
                    toast.error(data.message);
                    this.setState({checked: true, verified: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
    }

    render(){

        if(!this.state.checked){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Accepting Gatekeeper Role
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <Spinner loadingText="Just a moment..." />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10, maxWidth: 450}}>
                    <div className="card-header" style={{margin: -10}}>
                        Gatekeeper Role
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.verified ? (
                                <>
                                    <div style={{marginBottom: '10px'}}>
                                        <div className="alert alert-info">
                                            <h3><i className="fa fa-check"></i> Accepted!</h3>
                                            <p>Hi {this.state.gatekeeper.first}, thank you for accepting {this.state.forUser.first}'s invitation. You are now {this.state.forUser.first}'s Gatekeeper!</p>
                                        </div>
                                    </div>
                                    {this.state.gatekeeper.hasAccount ? (
                                    <div style={{marginTop: '20px'}}>
                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                            <NavLink className="btn btn-link" to="/account">Go To Login</NavLink>
                                        </div>
                                    </div>
                                    ):(
                                        <div style={{marginTop: '20px'}}>
                                            <h4>Want Your Own Account?</h4>
                                            <div>NotesForAfter makes it easy for you to share notes, videos, or other files with loved ones and significant people in your life when you pass away, and right now you can get an account for free. Create an account today!</div>
                                            <div style={{marginTop: '20px'}}>
                                                <div style={{margin: '20px auto', textAlign: 'center'}}>
                                                    <NavLink className="btn btn-link" to="/account">Register Now</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ):(
                                <>
                                    <div>It looks like the code or link you used is no longer valid. Please try again. If you continue to have trouble, please email <a href="mailto:support@notesforafter.com">support@notesforafter.com</a>.</div>
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}