import React from 'react';
import {toast} from 'react-toastify';
import { NavLink } from 'react-router-dom';
import Spinner from 'components/Spinner';

export default class Reject extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            verified: false,
            gk_dne: false,
            all_dne: false,
            submitting: false
        }
    }

    componentDidMount(){
        this.doReject();
    }

    doReject = () => {
            fetch('https://app.notesforafter.com/operations/invitations/reject/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    code: this.props.code,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({checked: true, verified: true, gatekeeper: data.gatekeeper, forUser: data.forUser, gk_dne: data.gk_dne, all_dne: data.dne});
                }else{
                    toast.error(data.message);
                    this.setState({checked: true, verified: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
    }

    submitPreferences = () => {
        if(this.state.all_dne){
            this.doBlockAllEmails();
        }else if(this.state.gk_dne){
            this.doBlockGatekeeperRequests();
        }
    }

    doBlockAllEmails = () => {
        this.setState({submitting: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/invitations/blockAllEmails/', {
                method: "POST",
                    credentials: 'include',
                    body: JSON.stringify({ 
                        code: this.props.code,
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    if(data.status==='success'){
                        toast.success('You have successfully opted out of all emails from NotesForAfter.');
                    }else{
                        toast.error(data.message);
                        this.setState({submitting: false})
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })   
        });
        
    }
    doBlockGatekeeperRequests = () => {
        this.setState({submitting: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/invitations/blockGatekeeperRequests/', {
                method: "POST",
                    credentials: 'include',
                    body: JSON.stringify({ 
                        code: this.props.code,
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    if(data.status==='success'){
                        toast.success('You have successfully opted out of all Gatekeeper Invitations.');
                    }else{
                        toast.error(data.message);
                        this.setState({submitting: false})
                    }
                })
                .catch((error)=>{
                    console.log(error);
                })  
        })
         
    }

    render(){

        if(!this.state.checked){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Rejecting Gatekeeper Role
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <Spinner loadingText="Just a moment..." />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10, maxWidth: 450}}>
                    <div className="card-header" style={{margin: -10}}>
                        Gatekeeper Role
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.verified ? (
                                <>
                                    <div style={{marginBottom: '10px'}}>
                                        <div className="alert alert-info">
                                            Hi {this.state.gatekeeper.first}, you have rejected {this.state.forUser.first}'s invitation. We will let {this.state.forUser.first} know that you are not interested in being their Gatekeeper.
                                        </div>
                                    </div>
                                    {this.state.gatekeeper.hasAccount ? (
                                    <div style={{marginTop: '20px'}}>
                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                            <NavLink className="btn btn-link" to="/account">Go To Login</NavLink>
                                        </div>
                                    </div>
                                    ):(
                                        <div style={{marginTop: '20px', fontSize: '14px'}}>
                                            <h4>More Options</h4>
                                            <div>If you already know that you're not interested in being a Gatekeeper for anyone else, you can opt-out now so that any future requests would be automatically declined.</div>
                                            <div>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <input checked={this.state.gk_dne} onClick={()=>this.setState({gk_dne: !this.state.gk_dne})} type="checkbox" />
                                                            </td>
                                                            <td>
                                                                Do not send me any Gatekeeper requests.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <input checked={this.state.all_dne} onClick={()=>this.setState({all_dne: !this.state.all_dne})} type="checkbox" />
                                                            </td>
                                                            <td>
                                                                Do not send me any emails about anything, ever.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <button disabled={(!this.state.gk_dne && !this.state.all_dne)} onClick={()=>this.submitPreferences()} className="btn btn-small btn-outline-secondary">Submit Preferences</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {!this.state.all_dne ? (
                                                <div>
                                                    <h4>Interested In Your Own Account?</h4>
                                                    <div>NotesForAfter makes it easy for you to share notes, videos, or other files with loved ones and significant people in your life when you pass away, and right now you can get an account for free. Create an account today!</div>
                                                    <div style={{marginTop: '20px'}}>
                                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                                            <NavLink className="btn btn-link" to="/account">Register Now</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            ):(
                                                <div>
                                                    If all emails are blocked, creating an account on NotesForAfter is not possible. If you later decide that you want an account, you would need to send an email from {this.state.gatekeeper.email} to support@notesforafter.com to request removal from the "Do Not Email" list.
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            ):(
                                <>
                                    <div>It looks like the code or link you used is no longer valid. Please try again. If you continue to have trouble, please email <a href="mailto:support@notesforafter.com">support@notesforafter.com</a>.</div>
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}