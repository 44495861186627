import FunInput from 'components/FunInput';
import React from 'react';
import {toast} from 'react-toastify';

export default class AddGatekeeper extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            submitting: false,
            first: '',
            last: '',
            email: '',
            phone: ''
        }
    }

    componentDidMount(){
        
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }

    finishAddingGatekeeper = ()=> {
        if(this.state.first==='' || this.state.last==='' || this.state.email===''){
            toast.error('Please be sure to fill in the First Name, Last Name, and Email Address of your new Gatekeeper.');
        }else{
            //MORE CHECKS

            //THEN
            this.setState({submitting: true}, ()=>{
                fetch('https://app.notesforafter.com/operations/gatekeepers/addGatekeeper/', {
                    method: "POST",
                    credentials: 'include',
                    body: JSON.stringify({ 
                        first: this.state.first,
                        last: this.state.last,
                        email: this.state.email,
                        phone: this.state.phone
                    })
                })
                .then((response)=>response.json())
                .then((data)=>{
                    if(data.status==='success'){
                        this.setState({submitting: false, email: '', first: '', last: '', phone: '', adding: false})
                        toast.success('Gatekeeper Added!');
                        this.props.onAddGatekeeper();
                    }else{
                        if(data.extra==='logged_out'){
                            localStorage.removeItem('token');
                            window.location.reload();
                        }else{
                            toast.error(data.message);
                            this.setState({submitting: false});
                        }
                    }
                })
                .catch(error=>console.log(error));
             })
        }
    }

    render(){
        return(
            <div style={{padding: '10px 20px'}}>
               
                    <div>
                        <FunInput label="First Name" value={this.state.first} onInputChange={(val)=>this.setState({first: val})} />
                    </div>
                    <div>
                        <FunInput label="Last Name" value={this.state.last} onInputChange={(val)=>this.setState({last: val})} />
                    </div>
                    <div>
                        <FunInput label="Email Address" value={this.state.email} onInputChange={(val)=>this.setState({email: val})} />
                    </div>
                    <div>
                        <FunInput label="Phone Number (Optional)" value={this.formatPhoneNumber(this.state.phone)} onInputChange={(val)=>this.setState({phone: val})} />
                    </div>

                    <div>
                        <button className="btn btn-sm btn-success" disabled={this.state.submitting} onClick={()=>this.finishAddingGatekeeper()}>Finish Adding Gatekeeper</button>
                    </div>

            </div>
        )
    }

}