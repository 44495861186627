import React from 'react';
import {toast} from 'react-toastify';

import AccountNav from 'components/account/AccountNav';

export default class ReceivedHome extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            files: {}
        }
    }

    componentDidMount(){
        this.fetchReceivedFiles();
    }

    fetchReceivedFiles = () => {
        fetch('https://app.notesforafter.com/operations/files/fetchReceivedFiles/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, files: data.files, fileCount: data.fileCount});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }


    openObjectDetail = (objectId, objectTitle) => {
        this.setState({objectDetailId: objectId, objectDetailTitle: objectTitle}, ()=>{
            this.setState({
                objectDetailModal: true,
                uploadFileModal: false, 
                selectionModal: false,
                createNoteModal: false,
                createVideoModal: false
            })
        })
    }

    
    render(){

        return(
            <>
            <div className="row">
                <div className="col-sm-5" style={{marginBottom: '10px'}}>
                    <div className="card">
                        <div className="card-body">
                            If there are files that have been sent to you by someone who has died or become incapacitated, you'll find them here.
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    
                        <AccountNav screensize={this.props.screensize} />

                        <div className="card" style={{borderTop: 'none', borderRadius: '0 16px 16px 16px'}}>
                            <div className="card-body">

                                {!this.state.loaded ? (
                                    <div><i className="fa fa-spinner fa-spin"></i> Loading Files...</div>
                                ):(

                                <>
                                
                                {this.props.screensize!=='small' && <h4 style={{marginBottom: '25px'}}>Files I've Received</h4>}
                                {this.state.fileCount===0 ? (
                                    <>
                                    <div>
                                        Currently there are no files available.
                                    </div>
                                    </>
                                ):(
                                <>
                                
                                <table className="table table-borderless" style={{marginTop: this.props.screensize!=='small' ? 0 : '15px'}}>
                                    <tbody>
                                        
                                        {this.state.files.map((item, i)=> {
                                            
                                            return(
                                                
                                                <React.Fragment key={i}>
                                                    <tr style={{borderBottom: '1px solid #EFEFEF'}}>
                                                        {this.props.screensize!=='small' && (
                                                            <td>
                                                                {(item.type==='image' || item.type==='JPG' || item.type==='PNG') && <i className="fa fa-image"></i>}
                                                                { (item.type==='MP4' || item.type==='MOV') && <i className="fa fa-play"></i>}
                                                                {(item.type==='PDF' || item.type==='pdf') && <i className="fa fa-file-o"></i>}
                                                                {(item.type==='CRYPTO') && <i className="fa fa-lock"></i>}
                                                            </td>
                                                            )
                                                        }

                                                            <td onClick={()=>this.openObjectDetail(item.id, item.title)}>
                                                            <div>
                                                                {item.title}
                                                            </div>
                                                            <div style={{fontSize: 12}}>
                                                                
                                                                <> Added {item.date_added} by {item.owner_first} {item.owner_last}</>
                                                                        
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-link" target="_blank" rel="noreferrer noopener" href={`https://app.notesforafter.com/files/open/?fc=${item.code}`}>
                                                                <i className="fa fa-eye"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            
                                            )
                                            })}
                                    </tbody>
                                </table>
                                </>
                                )}
                                </>
                                )}
                            </div>
                        </div>
                    
                </div>
            </div>

            </>
        )
    }
}