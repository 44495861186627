import React from 'react';
import {Container} from 'reactstrap';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from 'history';

import NavBar from 'components/NavBar';
import Footer from 'components/Footer2';
import Login from 'components/Login';
import AccountHome from 'components/account/AccountHome';
import PasswordReset from 'components/PasswordReset';
import PasswordResetFinish from 'components/PasswordResetFinish';
import CheckinLanding from './CheckinLanding';
import Accept from './invitations/Accept';
import Reject from './invitations/Reject';
//import HomePage from './HomePage';
import FileAccess from './recipients/FileAccess';
import ConfirmStatus from './ConfirmStatus';
import UpdateStatus from './UpdateStatus';
import RequestFileLinks from './recipients/RequestFileLinks';
import ConfirmPayment from './confirm/ConfirmPayment';
import ConfirmSubscription from './confirm/ConfirmSubscription';
import GatekeeperInvitation from './examples/GatekeeperInvitation';
import BetaQuestions from './beta/BetaQuestions';

const history = createBrowserHistory();

export default class App extends React.Component {
  
  constructor(){
    super();
    this.state = {
      screensize: 'unknown',
      containerMinHeight: 400,
      token: localStorage.getItem('token') || null
    }
  }

  componentDidMount() {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  resize() {
      let screensize = window.innerWidth <= 766 ? 'small' : 'large';
      let containerMinHeight = window.innerHeight-175;
      if (screensize !== this.state.screensize || containerMinHeight !== this.state.containerMinHeight) {
          this.setState({screensize: screensize, containerMinHeight: containerMinHeight});
      }
  }

  handleTokenSet = () => {
    let newToken = localStorage.getItem('token');
    if(newToken){
      this.setState({token: newToken});
    }
  }
  render(){

    return (
      <>
        <NavBar loggedIn={this.state.token===null ? false : true} screensize={this.state.screensize} />
        <Router history={history}>
          <Container style={{minHeight: this.state.containerMinHeight, padding: this.state.screensize==='large' ? 20 : 5, maxWidth: '900px'}} >

            {/*Anyone can access these*/}
            <Switch>
              {/*<Route exact path='/blog' render={()=> <div>We are at Blog!</div>} />*/}
              {/*<Route exact path='/' render={()=> <div>We are at home, not sure of login</div>} />*/}
            
              <Route exact path='/account/reset' render={()=><PasswordReset />} />
              <Route exact path='/resetPassword/:code' render={({match})=><PasswordResetFinish code={match.params.code} />} />
              <Route exact path='/checkin/:code' render={({match})=><CheckinLanding code={match.params.code} />} />
              <Route exact path='/gk/acceptInvitation/:code' render={({match})=><Accept code={match.params.code} />} />
              <Route exact path='/gk/rejectInvitation/:code' render={({match})=><Reject code={match.params.code} />} />
              <Route exact path='/gk/confirmStatus/:status/:code' render={({match})=><ConfirmStatus code={match.params.code} status={match.params.status} />} />
              <Route exact path='/gk/updateStatus/:status/:code' render={({match})=><UpdateStatus code={match.params.code} status={match.params.status} />} />
              <Route exact path='/fa/requestFileLinks' render={()=><RequestFileLinks />} />
              <Route exact path='/fa/:code' render={({match})=><FileAccess code={match.params.code} />} />

            {this.state.token===null && (
                <Route path='/' render={()=> <Login onTokenSet={()=>this.handleTokenSet()} />} />
            )}

            {/*ONLY LOGGED IN USER CAN ACCESS THESE*/}
              <Route exact path='/next' render={()=> <div>We are at Next!</div>} />

              <Route exact path='/confirm/payment' render={()=> <ConfirmPayment screensize={this.state.screensize} />} />
              <Route exact path='/confirm/subscription' render={()=> <ConfirmSubscription screensize={this.state.screensize} />} />
              <Route exact path='/examples/gatekeeperInvitation' render={()=> <GatekeeperInvitation screensize={this.state.screensize} />} />

              <Route path='/betaQuestions/:q' render={({match})=><BetaQuestions currentQuestion={match.params.q} screensize={this.state.screensize} />} />
              <Route path='/betaQuestions/' render={()=> <BetaQuestions currentQuestion={0} screensize={this.state.screensize}  />} />
              
              <Route path='/account' render={()=> <AccountHome screensize={this.state.screensize}  />} />

              <Route path='/' render={()=> <AccountHome screensize={this.state.screensize}  />} />
            </Switch>
            
          </Container>
        </Router>
        <Footer />  
        <ToastContainer />
      </>
    );
  }

}
