import React from 'react';
import ReactQuill from 'react-quill';
import ProgressBar from 'components/ProgressBar';

/* 
 * Simple editor component that takes placeholder text as a prop 
 */

class MyEditor extends React.Component {
    constructor (props) {
        super(props)
        this.state = { 
            editorHtml: this.props.html || '', 
            theme: 'snow',
            buttonColor: this.props.saveButtonColor || 'success',
            buttonText: this.props.saveButtonText || 'Save Changes',
            buttonMargin: this.props.saveButtonMargin || '10px',
            changeCount: 0,
            uploadingImage: false
        }
        this.reactQuillRef = null; // ReactQuill component
        this.quillRef = null;      // Quill instance

      this.modules = {
        toolbar: {
          container: !this.props.limited ? [[{'size': []}],['bold', 'italic', 'underline'], ['link','image', 'video']] : [[{'size': []}], ['bold', 'italic', 'underline'], ['link']],
          handlers: {
            image: this.imageHandler
          },
        },
        
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
      }
    }

    componentDidMount() {
        this.attachQuillRefs()
    }
    
    componentDidUpdate() {
        this.attachQuillRefs()
    }
    
    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }
    
    handleChange = (html) => {
        if(this.state.changeCount===2 && this.props.promptToSave){
            this.setState({ editorHtml: html, changeCount: this.state.changeCount+1}, ()=>{
                this.setState({addPulse: true});
            });
        }else{
            this.setState({ editorHtml: html, changeCount: this.state.changeCount+1});
        }
    }

    saveUpdate = () => {
      this.setState({addPulse: false}, ()=> {
        this.props.onSaveUpdate(this.state.editorHtml);
      })  
    }
    
    /*
    imageHandler = () => {
        console.log('trying to upload');
        //this.insertUploadedImage('some text here');
        
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = ()=> {
            const file = input.files[0];
            this.setState({uploadingImage: true});
            const data = new FormData();
            data.append('file', file);
            fetch('https://www.freefunder.com/v2/operations/uploads/', {
            method: "POST",
            credentials: 'include',
            body: data
            })
            .then((response) => response.json())
            .then((data) => {
                this.setState({uploadingImage: false});
                this.insertUploadedImage(data.endfile);
                console.log(data);
            })
            .catch( error => console.log(error));
        }
        
    }
    */

    /*
    insertUploadedImage = (src) => {
        const cursorPosition = this.quillRef.getSelection().index;
        this.quillRef.insertEmbed(cursorPosition, 'image', src);
        this.quillRef.setSelection(cursorPosition + 1);
    }
    */
    
    render () {
      return (
        <div>
            {this.state.uploadingImage && (
                <React.Fragment>
                    <div>Uploading...</div>
                    <ProgressBar />
                </React.Fragment>
            )}
          <ReactQuill 
            theme={this.state.theme}
            onChange={this.handleChange}
            value={this.state.editorHtml}
            modules={this.modules}
            formats={MyEditor.formats}
            placeholder={this.props.placeholder}
            ref={(el) => {this.reactQuillRef = el}}
           />
           {typeof(this.props.onSaveUpdate)!=='undefined' && (
               <div style={{textAlign: 'center', marginTop: this.state.buttonMargin}}>
                   <button className={`btn btn-${this.state.buttonColor}${this.state.addPulse ? ' pulse-button' : ''}`} onClick={()=>this.saveUpdate()}>{this.state.buttonText}</button>
                </div>
           )}
         </div>
       )
    }
  }
  
  /* 
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  MyEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'indent',
    'link'
    //'link', 'image', 'video'
  ]
  
export default MyEditor;

  