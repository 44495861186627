import React from 'react';
import {toast} from 'react-toastify';
import FunInput from 'components/FunInput';
//import AutocompleteRecipients from 'components/AutocompleteRecipients';

import { Typeahead } from 'react-bootstrap-typeahead';

export default class ObjectDetail extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            object: {},
            originalObject: {},
            recipientOptions: [],
            unsaved: false,
            newRecipientFirst: '', 
            newRecipientLast: '',
            newRecipientEmail: '',
            showPasswordOptions: false,
            passwordProtect: 0,
            password: '',
            passwordHint: ''
        }
    }

    componentDidMount(){
        this.fetchObjectDetail();
    }

    togglePasswordOptions = () => {
        this.setState({showPasswordOptions: !this.state.showPasswordOptions})
    }

    togglePasswordProtect = () => {
        if( parseInt(this.state.object.has_pass)===1 && this.state.passwordProtect===1){
            //WE ARE REMOVING AN EXISTING PASSWORD
            //DO DB UPDATE//
            this.changeFieldOnServer('password', '');
            this.changeFieldOnServer('password_hint', '');
            this.changeFieldOnServer('has_pass', 0);

            //DO LOCAL STATE UPDATE//
            this.setState({passwordProtect: 0});
            setTimeout(()=>{
                this.fetchObjectDetail()
            }, 1000); 
        }else{
            this.setState({passwordProtect: this.state.passwordProtect===1 ? 0 : 1});
        }
    }

    fetchObjectDetail = () => {
        fetch('https://app.notesforafter.com/operations/files/fetchObjectDetail/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                object_id: this.props.objectId
            })
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, object: {...data.object}, originalObject: {...data.object}, recipientOptions: data.recipientOptions, exceedsRecipients: data.exceedsRecipients, exceedsStorage: data.exceedsStorage, passwordProtect: parseInt(data.object.has_pass), passwordHint: data.object.password_hint});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    onInputChange = (input, val) => {
        let {object} = this.state;
        object[input] = val;
        this.setState({object: object});
        //console.log(this.state.object);
        //console.log(this.state.originalObject);
        if(input==='release_level'){
            this.changeFieldOnServer(input, val);
        }
     }

     onBlurTitle = () => {
         if(this.state.object.title!==this.state.originalObject.title){
             //WE RUN THE UPDATE TO THE SERVER
            this.changeFieldOnServer('title', this.state.object.title);
         }
     }

     changeFieldOnServer = (field, value) => {
        fetch('https://app.notesforafter.com/operations/files/updateField/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                object_id: this.state.object.id,
                field: field,
                value: value
            })
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.fetchObjectDetail();
                toast.success('Updated!');
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
     }

     handleAutoCompleteSelected = (recipient) => {
         console.log(recipient);
         if(typeof(recipient)!=='undefined'){
            if(recipient.customOption){
                if(this.state.newRecipientEmail!==recipient.email){
                    //PROMPT FOR DETAILS//
                    this.setState({addingNewRecipient: true, newRecipientEmail: recipient.email});
                }
            }else{
                this.setState({addingNewRecipient: false, newRecipientEmail: '', newRecipientFirst: '', newRecipientLast: ''}, ()=>{
                    this.addRecipient(recipient.id, recipient.first, recipient.last, recipient.email);
                });
                
            }
        }
     }

     finishNewRecipient = () => {
         if(this.state.newRecipientEmail!=='' && this.state.newRecipientFirst!=='' && this.state.newRecipientLast!==''){
            this.addRecipient('new', this.state.newRecipientFirst, this.state.newRecipientLast, this.state.newRecipientEmail);
         }else{
             toast.error('Be sure to fill in all the required information before submitting!');
         }
     }

     addRecipient = (recipient_id, first, last, email) => {
         this.setState({submittingRecipient: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/files/addRecipient/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    object_id: this.state.object.id,
                    recipient_id: recipient_id,
                    first: first,
                    last: last,
                    email: email
                })
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.fetchObjectDetail();
                    this.setState({submittingRecipient: false, newRecipientEmail: '', newRecipientFirst: '', newRecipientLast: '', showAddRecipientForm: false})
                    toast.success('Recipient Added!');
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                        toast.error(data.message);
                    }
                }
            })
            .catch(error=>console.log(error));
         })
     }
     startRemoveRecipient = (recipient_id) => {
         if(window.confirm('Are you sure you want to remove this Recipient? They will no longer have access to this file.')){
             this.removeRecipient(recipient_id);
         }
     }

     removeRecipient = (recipient_id) => {
        fetch('https://app.notesforafter.com/operations/files/removeRecipient/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                object_id: this.state.object.id,
                recipient_id: recipient_id
            })
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.fetchObjectDetail();
                this.setState({submittingRecipient: false, newRecipientEmail: '', newRecipientFirst: '', newRecipientLast: '', showAddRecipientForm: false})
                toast.success('Recipient Removed.');
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
     }

     deleteFile = () => {
        if(window.confirm('Are you sure you want to permanently delete this file?')){
            fetch(`https://app.notesforafter.com/operations/files/deleteFile/?id=${this.state.object.id}`, {
                credentials: 'include'
            })
            .then(resp => resp.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.props.onDeleteFile();
                }else{
                    toast.error(data.message);
                }
            })
            .catch(error=>console.log(error));
        }
     }

     addPassword = () => {
        if(this.state.password.length>7 && this.state.passwordHint.length>3){
            fetch('https://app.notesforafter.com/operations/files/addPassword/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    file_id: this.state.object.id,
                    password: this.state.password,
                    password_hint: this.state.passwordHint
                })
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.fetchObjectDetail();
                    toast.success('Your password has been saved.');
                    this.setState({showPasswordOptions: false});
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                        toast.error(data.message);
                    }
                }
            })
            .catch(error=>console.log(error));
        }else{
            toast.error('Be sure to have at least 8 characters in your password and include a password hint!');
        }
    }

    render(){
        if(!this.state.loaded){
            return (
                <div className="card" style={{borderTop: 'none'}}>
                    <div className="card-body">
                        <i className="fa fa-spinner fa-spin"></i> Loading Object Details...
                    </div>
                </div>
            )
        }
        return(
            <>
                {this.state.exceedsStorage && (
                    <div>
                        <div className="alert alert-danger">This file exceeds the Storage Limit for your account, and will not be sent to any Recipients!</div>
                    </div>
                )}
                {this.state.exceedsRecipients && (
                    <div>
                        <div className="alert alert-danger">This file exceeds the Recipient Limit for your account, and will not be sent to any Recipients beyond the first three listed! <a href="https://app.notesforafter.com/account">Upgrade your account</a> to allow up to 20 Recipients for each file!</div>
                    </div>
                )}
                {this.state.unsaved && (
                    <div className="alert alert-info">Be sure to save any changes!</div>
                )}
                <table className="table table-sm table-borderless">
                    <tbody>
                    <tr style={{textAlign: 'center', align: 'center', fontSize: 16}}>
                        <td style={{paddingBottom: '.8rem'}}>Type: {this.state.object.type}</td>
                        <td style={{paddingBottom: '.8rem'}}>Size: {this.state.object.megabytes} mb</td>
                    </tr>
                    <tr><td colSpan={2}><strong>Title</strong></td></tr>
                    <tr>
                        <td colSpan={2}><input className="form-control" type="text" onBlur={(e)=>this.onBlurTitle(e)} value={this.state.object.title} onChange={(e)=>this.onInputChange('title', e.target.value)} /></td>
                    </tr>
                    <tr><td colSpan={2}><strong>Release Timing</strong></td></tr>
                    <tr>
                        <td colSpan={2}>
                            <select onChange={(e)=>this.onInputChange('release_level', e.target.value)} value={this.state.object.release_level} className="form-control">
                                
                                <option value={1}>Release When I Am Incapacitated</option>
                                <option value={2}>Release When I Have Died</option>
                            </select>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style={{paddingLeft: '.3rem'}}><strong>Recipient(s)</strong></div>
                <table className="table table-sm table-borderless">
                    <tbody>
                        {this.state.object.recipients.map((recipient, i)=>{
                            return(
                                <React.Fragment key={i}>
                                    {this.state.exceedsRecipients && i===3 && (
                                        <tr>
                                            <td colSpan={2}>
                                                <span style={{color: '#FF0000'}}>The following Recipients will not actually be sent this file.</span>
                                            </td>
                                        </tr>
                                    )}
                                        <tr>
                                            <td style={{color: (this.state.exceedsRecipients && i>2) ? 'rgb(226 153 153)' : '#000'}}>
                                                <div>{recipient.first} {recipient.last}</div>
                                                <div style={{fontSize: 12}}>{recipient.email}</div>
                                            </td>
                                            <td align="right">
                                                <button onClick={()=>this.startRemoveRecipient(recipient.id)} className="btn btn-sm btn-link">
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                </React.Fragment>
                            )
                        })}
                        {this.state.object.recipients.length===0 && (
                            <tr>
                                <td colSpan={2}>
                                    You do not have a recipient yet! Add one now!
                                </td>
                            </tr>
                        )}
                        <tr style={this.state.showAddRecipientForm ? {backgroundColor: '#F8F8F8'} : {backgroundColor: '#FFF'}}>
                            <td>
                                {!this.state.showAddRecipientForm ? (
                                    <button onClick={()=>this.setState({showAddRecipientForm: this.state.showAddRecipientForm ? false : true})} className={ this.state.showAddRecipientForm ? 'btn btn-sm btn-outline-danger' : 'btn btn-sm btn-outline-secondary'}>{this.state.showAddRecipientForm ? 'Cancel' : 'Add Recipient'}</button>
                                ):(
                                    <h4>Add Recipient</h4>
                                )}
                            </td>
                            <td align="right">
                                {this.state.showAddRecipientForm && (
                                    <button onClick={()=>this.setState({showAddRecipientForm: this.state.showAddRecipientForm ? false : true})} className={ this.state.showAddRecipientForm ? 'btn btn-sm btn-outline-danger' : 'btn btn-sm btn-outline-secondary'}>{this.state.showAddRecipientForm ? 'Cancel' : 'Add Recipient'}</button>
                                )}
                            </td>
                        </tr>
                        {this.state.showAddRecipientForm && (
                            <>
                            {this.state.object.recipients.length>=this.state.object.recipient_limit ? (
                                <tr style={{backgroundColor: '#F8F8F8'}}>
                                    <td colSpan={2}>
                                        The maximum number of Recipients allowed based on your plan is {this.state.object.recipient_limit}. To add more Recipients, <a href="https://app.notesforafter.com/account">upgrade your plan</a>!
                                    </td>
                                </tr>
                            ):(
                            <>     
                                <tr style={{backgroundColor: '#F8F8F8'}}>
                                    <td colSpan={2}>
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="email"
                                            onChange={(recipient)=>this.handleAutoCompleteSelected(recipient[0])}
                                            options={this.state.recipientOptions}
                                            placeholder="Email Address"
                                            allowNew
                                            newSelectionPrefix="Add New Recipient: "
                                            style={{marginBottom: 15}}
                                        />
                                        {/*<AutocompleteRecipients placeholder="Recipient Email" onOptionSelected={(recipient)=>this.handleAutoCompleteSelected(recipient)} />*/}
                                    </td>
                                </tr>
                                {this.state.addingNewRecipient && (
                                    <>
                                        <tr style={{backgroundColor: '#F8F8F8'}}>
                                            <td colSpan={2}>
                                                <FunInput label="First Name" value={this.state.newRecipientFirst} onInputChange={(val)=>this.setState({newRecipientFirst: val})} />
                                            </td>
                                        </tr>
                                        <tr style={{backgroundColor: '#F8F8F8'}}>
                                            <td colSpan={2}>
                                                <FunInput label="Last Name" value={this.state.newRecipientLast} onInputChange={(val)=>this.setState({newRecipientLast: val})} />
                                            </td>
                                        </tr>
                                        <tr style={{backgroundColor: '#F8F8F8'}}>
                                            <td colSpan={2} align="center">
                                                <button disabled={this.state.submittingRecipient ? true : undefined} onClick={()=>this.finishNewRecipient()} className="btn btn-sm btn-outline-secondary">Finish Adding Recipient</button>
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </>
                            )}
                            </>
                        )}
                    </tbody>
                </table>
                <div style={{marginTop: 25}}>
                    <a className="btn btn-sm btn-outline-info" target="_blank" rel="noreferrer noopener" href={`https://app.notesforafter.com/files/open/?fc=${this.state.object.code}`}>
                        <i className="fa fa-eye"></i> View
                    </a> &nbsp; 
                    <button onClick={()=>this.togglePasswordOptions()} className="btn btn-sm btn-outline-info">
                        <i className="fa fa-lock"></i> Password Options
                    </button>
                    <button style={{float: 'right'}} onClick={()=>this.deleteFile()} className="btn btn-sm btn-outline-danger">
                        <i className="fa fa-times"></i> Delete
                    </button>
                </div>
                {this.state.showPasswordOptions && (
                    <div style={{margin: '10px auto', padding: '10px', border: '1px solid #CCC', borderRadius: 10, backgroundColor: '#F8F8F8'}}>
                        <h5>Password Options</h5>
                        <div>
                            <p>Setting a password for this file means that you will need to enter a password to access it even while logged into your account. It does not affect whether or not a Recipient needs to enter a password (if you want to password-protect the file itself, you should encrypt it before uploading!)</p>
                        </div>
                        {this.state.passwordProtect===1 ? (
                            <div>
                                <p><input type="checkbox" onChange={()=>this.togglePasswordProtect()} checked /> Password protect this file</p>
                                <p><input type="password" placeholder={parseInt(this.state.object.has_pass)===1 ? 'New Password (Change)' : 'Password'} className="form-control"value={this.state.password} onChange={(e)=>this.setState({password: e.target.value})} /></p>
                                <p>Add a Password Hint:<br />
                                <input type="text" placeholder="Password Hint" className="form-control"value={this.state.passwordHint} onChange={(e)=>this.setState({passwordHint: e.target.value})} />
                                </p>
                                <p>
                                    <button className="btn btn-outline-secondary" onClick={()=>this.addPassword()}>Submit Password</button>
                                </p>
                            </div>
                        ):(
                            <div>
                                <p><input type="checkbox" onChange={()=>this.togglePasswordProtect()} /> Password protect this file</p>
                            </div>
                        )}
                       
                    </div>
                )}
                <div style={{marginTop: 10}}>
                    <div style={{paddingLeft: '.3rem'}}><strong>Move File To Different Vault</strong></div>
                    <select className="form-control" value={this.state.object.vault_id} onChange={(e)=>this.changeFieldOnServer('vault_id', e.target.value)}>
                        {this.props.vaults.map((vault, i)=>{
                            return (
                                <option key={i} value={vault.id}>{vault.title}</option>
                            )
                        })}
                    </select>
                </div>
            </>
        )
    }
}

