
import React from 'react';
import {toast} from 'react-toastify';
import FunInput from 'components/FunInput';

export default class AddVault extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            newVaultName: '',
            submitting: false,
            loaded: false,
            defaultRecipient: 0
        }
    }

    componentDidMount(){
        this.loadRecipients();
    }

    loadRecipients = () => {
        fetch('https://app.notesforafter.com/operations/recipients/fetchRecipients/', {
                credentials: 'include',
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.setState({recipients: data.recipientOptions, loaded: true})
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                       toast.error(data.message);
                    }
                }
            })
            .catch(error=>console.log(error));
    }

    addVault = () => {
        this.setState({submitting: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/files/addVault/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    vault_name: this.state.newVaultName,
                    default_recipient: this.state.defaultRecipient
                })
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    toast.success('Your new Vault was added.');
                    this.setState({submitting: false}, ()=>{
                        this.props.onVaultAdded(data.newId, this.state.newVaultName);
                    })
                    
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                        this.setState({submitting: false}, ()=>{
                            toast.error(data.message);
                        })
                    }
                }
            })
            .catch(error=>console.log(error));
        })
    }

    render(){
        return(
            <table className="table table-sm table-borderless">
                <tbody>
                    <tr>
                        <td>What do you want to call your new Vault?</td>
                    </tr>
                    <tr>
                        <td><FunInput label='New Vault Name' value={this.state.newVaultName} onInputChange={(val)=>this.setState({newVaultName: val})} /></td>
                    </tr>
                    {this.state.loaded && (
                        <>
                            <tr>
                                <td>When you add new files to this Vault, do you want a Default Recipient (you can always add or change Recipients to files later)?</td>
                            </tr>
                            <tr>
                                <td>
                                    <select className="form-control" value={this.state.defaultRecipient} onChange={(e)=>this.setState({defaultRecipient: e.target.value})}>
                                        <option key={9999} value={0}>No Default Recipient</option>
                                        {this.state.recipients.map((rec, i)=>{
                                            return (
                                                <option key={i} value={rec.id}>{rec.first} {rec.last} ({rec.email})</option>
                                            )
                                        })}
                                    </select>
                                </td>
                            </tr>
                        </>
                    )}
                    
                    <tr>
                        <td style={{align: 'center', textAlign: 'center'}}>
                            <button disabled={this.state.submitting} onClick={()=>this.addVault()} className="btn btn-sm btn-outline-secondary">
                                Add Vault
                            </button>
                        </td>
                    </tr>
                    
                </tbody>
            </table>       
        )
    }
}

