import React from 'react';
import {toast} from 'react-toastify';
import {
    Collapse,
    Navbar,
    Container,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';

export default class NavBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            alertsLoaded: false,
            loggedIn: this.props.loggedIn || 'no',
            isOpen: false,
            token: localStorage.getItem('token') || null,
            user: JSON.parse(localStorage.getItem('user')) || null
        }
    }

    componentDidMount(){
        this.checkForUser();
    }

    toggle = () => {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

    checkForUser = () => {
        if(this.props.loggedIn){
            let user = JSON.parse(localStorage.getItem('user'));
            if(!user || typeof(user)==='undefined' || typeof(user.id)==='undefined'){
                this.getUserInfo();
            }
        }
    }

    getUserInfo = () => {
        fetch('https://app.notesforafter.com/operations/user/fetchUserInfo/', {
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                this.setState({currentUser: data.user}, ()=> {
                    localStorage.setItem('user', JSON.stringify(data.user));  
                });
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    //localStorage.removeItem('user');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    onImageError = (ev) => {
        ev.target.src = 'https://www.freefunder.com/v2/images/no-photo.png';
    }

    render(){
        //let loc = window.location.pathname;
        //let manage = loc.indexOf('/manage')
        //let create = loc.indexOf('/create');

        return(
            <Navbar light expand="md" style={{borderTop: '5px solid #053C79', backgroundColor: '#FFF', padding: '0 15px'}}>
                <Container>
                <NavbarBrand href="/">
                    <img src="https://app.notesforafter.com/images/notesforafter_logo_small_white.png" style={{height: this.props.screensize==='small' ? 30 : 40}} alt="Notes For After logo" />
                </NavbarBrand>
                
                {/*<NavbarToggler className="ml-auto" onClick={this.toggle} />*/}
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                    <NavItem>
                        <a className="nav-link" href="https://www.notesforafter.com/how-it-works/" target="_blank" rel="noreferrer">How It Works</a>
                    </NavItem>
                    <NavItem>
                        <a className="nav-link" href="https://www.notesforafter.com/#faqs" target="_blank" rel="noreferrer">FAQs</a>
                    </NavItem>
                    <NavItem>
                        <a className="nav-link" href="https://www.notesforafter.com/blog/" target="_blank" rel="noreferrer">Blog</a>
                    </NavItem>
                    </Nav>
                </Collapse> 
                <div style={{display: 'flex', alignItems: 'center'}}>
                <div className="d-md-none">
                    <Nav>
                    <UncontrolledDropdown>
                        <DropdownToggle nav style={{color: '#000', fontSize: '30px'}}>
                            <i className="fa fa-bars"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem>
                                <a className="nav-link" href="https://www.notesforafter.com/how-it-works/" target="_blank" rel="noreferrer">How It Works</a>
                            </DropdownItem>
                            <DropdownItem>
                                <a className="nav-link" href="https://www.notesforafter.com/#faqs" target="_blank" rel="noreferrer">FAQs</a>
                            </DropdownItem>
                            <DropdownItem>
                                <a className="nav-link" href="https://www.notesforafter.com/blog/" target="_blank" rel="noreferrer">Blog</a>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    </Nav>
                </div>
                {this.props.loggedIn ? (
                   <Nav navbar>
                   <UncontrolledDropdown>
                       <DropdownToggle nav caret>
                           {this.state.currentUser ? (
                               <img onError={this.onImageError} src={this.state.currentUser.photo} alt='profile' height="40" style={{border: '1px solid #CCC', borderRadius: '25px'}}  />
                           ):(
                               <img src="https://www.freefunder.com/images/no-photo.png" alt="profile" height="40" style={{border: '1px solid #CCC', borderRadius: '25px'}} />
                           )}
                       </DropdownToggle>
                       <DropdownMenu right>
                       <DropdownItem>
                             <a className="nav-link" href="/account/" title="Account">Account</a>
                       </DropdownItem>
                       <DropdownItem>
                           <a className="nav-link" href="/account/files/" title="Objects">Vaults and Files</a>
                       </DropdownItem>
                       <DropdownItem>
                           <a className="nav-link" href="/account/gatekeepers/" title="Gatekeepers">Gatekeepers</a>
                       </DropdownItem>
                       <DropdownItem>
                           <a className="nav-link" href="/account/checkin/" title="Check In Sequence">Check In Sequence</a>
                       </DropdownItem>
                       <DropdownItem divider />
                       {this.state.currentUser && this.state.currentUser.id==='1452' && (
                            <React.Fragment>
                                <DropdownItem>
                                    <a className="nav-link" href="/admin/" title="Admin">Admin</a>
                                </DropdownItem>
                                <DropdownItem divider />
                            </React.Fragment>
                       )}
                       <DropdownItem>
                           <a className="nav-link" href="/account/logout" title="Logout">Logout</a>
                       </DropdownItem>
                       </DropdownMenu>
                   </UncontrolledDropdown>
                   </Nav>
               ):(
                   <Nav navbar>
                       <NavItem>
                            <a className="btn btn-sm btn-success" href="/account">Log In</a>
                        </NavItem>
                   </Nav>
               )}
                </div>   
                </Container>
            </Navbar>
        )
    }
}