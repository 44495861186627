import React from 'react';
import {toast} from 'react-toastify';
import FunInput from 'components/FunInput';
import { NavLink } from 'react-router-dom';

export default class PasswordReset extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            emailSubmitted: false,
            emailReceived: false
        }
    }

    componentDidMount(){
        
    }

    submitEmail = () => {
        this.setState({emailSubmitted: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/login/passwordReset/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    email: this.state.email,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({emailReceived: true}, ()=>{
                        fetch('https://app.notesforafter.com/operations/send/emailQ/', {});
                    })
                }else{
                    toast.error(data.message);
                    this.setState({emailSubmitted: false, emailReceived: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
        })
    }

    render(){

        if(this.state.emailReceived){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Password Reset Link Sent
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <div style={{fontSize: '14px', marginBottom: '10px'}}>If an account exists for email address {this.state.email} we have sent a password reset link. Check your email and click on the link to reset your password!</div>
                                <div style={{margin: '20px auto', textAlign: 'center'}}>
                                    <NavLink className="btn btn-link" to="/account">Back To Login</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10}}>
                    <div className="card-header" style={{margin: -10}}>
                        Send Password Reset Link
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <div style={{fontSize: '14px', margin: '10px auto'}}>Enter your email address below. If an account exists with that email address, we will send a password reset link to it.</div>
                            <div style={{marginTop: '20px'}}>
                                <FunInput type="text" label="Email Address" value="" onInputChange={(val)=>this.setState({email: val})} /> 
                                <div style={{textAlign: 'center'}}>
                                    <button disabled={this.state.emailSubmitted} className="btn btn-outline-secondary" onClick={()=>this.submitEmail()}>Send Reset Link</button>
                                </div>
                                <div style={{margin: '20px auto', textAlign: 'center'}}>
                                    <NavLink className="btn btn-link" to="/account">Back To Login</NavLink>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}