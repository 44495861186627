import React, {useState} from 'react';


export default function FunInput(props) {

    const [label] = useState(props.label || 'Input');
    const [value, setValue] = useState(props.value || '');
    const [type] = useState(props.type || 'text');

    const handleInputChange = (e) => {
        setValue(e.target.value);
        props.onInputChange(e.target.value);
    }

    const handleBlur = (e) => {
        if(typeof props.onBlur === "function"){
            props.onBlur(e);
        }
    }

    
    return(
        <div className={value==='' ? 'fun-label-wrapper' : 'fun-label-wrapper has-value'}>
            {type==='textarea' ? (
                <textarea className={props.addedClasses ? `form-control fun-label-input ${props.addedClasses}` : 'form-control fun-label-input'} value={value} onChange={(e)=>handleInputChange(e)} required={props.required} />
            ):(
                <input type={type} onBlur={(e)=>handleBlur(e)} className={props.addedClasses ? `form-control fun-label-input ${props.addedClasses}` : 'form-control fun-label-input'} value={value} onChange={(e)=>handleInputChange(e)} required={props.required} />
            )}
            <div className="fun-label-label">{label}</div>
        </div>
    )
    
}