import React from 'react';
import {NavLink} from 'react-router-dom';

export default class AccountNav extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nothing: 'yep'
        }
    }
    render(){
        return (
            <>
                <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink exact to='/account/files' className="nav-link">Files</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to='/account/gatekeepers' className="nav-link">Gatekeepers</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact to='/account/checkin' className="nav-link">Check In</NavLink>
                        </li>
                        {this.props.screensize==='large' && (
                            <li className="nav-item">
                                <NavLink exact to='/account' className="nav-link">Account</NavLink>
                            </li>
                        )}
                    </ul>
            </>
        )
    }
}