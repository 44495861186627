import React from 'react';
//import { NavLink } from 'react-router-dom';
//import {toast} from 'react-toastify';

import StorageRecommendations from 'components/addStorage/StorageRecommendations';
import { Link } from 'react-router-dom';

export default class FilesInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: true,
            showStorageOptions: this.props.screensize!=='small' ? true : false,
            hasBetaQuestions: 'no'
        }
    }
    componentDidMount(){
        this.checkBetaStatus();
    }

    checkBetaStatus = () => {
        fetch('https://app.notesforafter.com/operations/beta/checkBetaStatus/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success' && data.hasQuestions==='yes'){
                this.setState({hasBetaQuestions: data.hasQuestions})
            }
        })
        .catch(error=>console.log(error));
    }

    render(){
        let progressPercent = (this.props.mbData.mb_total/this.props.mbData.mb_max)*100;
        let showPercent = progressPercent.toFixed(1);
        return (
            <div>
                {this.state.hasBetaQuestions==='yes' && (
                    <div className="alert alert-warning" style={{marginBottom: 10}}>
                        <strong>Beta Testers!</strong> You can get 50mb of extra permanent storage space by <Link to="/betaQuestions/" style={{textDecoration: 'underline'}}>answering questions</Link>!
                    </div>
                )}
                <h5>Storage Space</h5>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="progress" style={{flex: 17, marginRight: 10}}>
                        <div className="progress-bar" role="progressbar" style={{width: progressPercent+'%'}} aria-valuenow={progressPercent} aria-valuemin="0" aria-valuemax="100">
                            
                        </div>
                    </div>
                    <div style={{flex: 3, color: showPercent>85 ? '#FF0000' : showPercent>35 ? '#000' : 'green'}}>{showPercent}%</div>
                </div>
                <div>{this.props.mbData.mb_total_pretty}mb used out of {this.props.mbData.mb_max_pretty}mb.</div>
                
                {/*
                <div style={{marginTop: 20, border: '1px solid #EFEFEF', padding: 10, borderRadius: 16}}>
                    <h4>Add 10,000mb Of Space</h4>
                    <p>Get 1,000 times the storage space, plus up to 25 Recipients, for as little as $3.95/mo!</p>
                    <p>
                        <NavLink to="/account">Add Storage Space</NavLink>
                    </p>
                </div>
                */}
                { (this.props.screensize!=='small' || this.state.showStorageOptions) ? (
                    <StorageRecommendations screensize={this.props.screensize} />
                ):(
                    <button className="btn btn-link" onClick={()=>this.setState({showStorageOptions: true})}>Get more storage</button>
                )}
                
            </div>
        )
    }
}