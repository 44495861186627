import React from 'react';
import {toast} from 'react-toastify';

import AccountNav from 'components/account/AccountNav';
import CheckinInfo from 'components/checkin/CheckinInfo';
import { Link } from 'react-router-dom';

export default class CheckInSequence extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            sequence: {},
            userStatus: {},
            showInfo: this.props.screensize==='small' ? false : true
        }
    }

    componentDidMount(){
        this.fetchSequence();
    }

    fetchSequence = () => {
        fetch('https://app.notesforafter.com/operations/user/fetchSequence/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, sequence: data.sequence, userStatus: data.userStatus});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    updateSequenceItem = (id, field, val) => {
        fetch('https://app.notesforafter.com/operations/user/updateSequenceItem/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                id: id,
                field: field,
                value: val
            })
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status!=='success'){
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }
 
    onSelectChange = (index, field, val) => {
       let newSequence = this.state.sequence;
       newSequence[index][field] = val;
       this.setState({sequence: newSequence});
       this.updateSequenceItem(newSequence[index].id, field, val);
    }

    addSequenceStep = () => {
        fetch('https://app.notesforafter.com/operations/user/addSequenceStep/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({sequence: data.sequence});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    removeSequenceStep = () => {
        fetch('https://app.notesforafter.com/operations/user/removeSequenceStep/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({sequence: data.sequence});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }

    render(){

        let sequenceCount = this.state.sequence.length;
        let oneLess = sequenceCount-1;
        return(

            <div className="row">
                <div className="col-sm-5" style={{marginBottom: '10px'}}>
                    <div className="card">
                        <div className="card-body">
                            {this.state.loaded && (
                                <>
                                {this.state.showInfo ? (
                                    <CheckinInfo user={this.state.user} closeInfo={()=>this.setState({showInfo: false})} screensize={this.props.screensize} />
                                ):(
                                  <>
                                    <div style={{position: 'absolute', top: '10px', right: '10px'}}>
                                        <button className="btn btn-sm" onClick={()=>this.setState({showInfo: true})}>
                                            <i className="fa fa-question-circle-o"></i> Info
                                        </button>
                                    </div>
                                    <h5>Set Up Check-Ins</h5>
                                  </>  
                                )}
                                
                            </>
                                
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    
                        <AccountNav screensize={this.props.screensize} />

                        <div className="card" style={{borderTop: 'none'}}>
                            <div className="card-body">

                            {!this.state.loaded ? (
                                <div><i className="fa fa-spinner fa-spin"></i> Loading Check In Sequence...</div>
                            ):(
                                <>
                                <h4 style={{marginBottom: '25px'}}>Check In Sequence</h4>
                                {this.state.userStatus.email_confirmed!=='1' && (
                                    <div className="alert alert-warning">Be sure to <Link to="/account">verify your email address</Link> so that we can do check-ins!</div>
                                )}
                                <table className="table table-borderless">
                                    <tbody>
                                        {this.state.sequence.map((item, i)=> {
                                            
                                            return(
                                                this.props.screensize!=='small' ? (
                                                    <React.Fragment key={i}>
                                                    <tr style={{borderBottom: '1px solid #EFEFEF'}}> 
                                                        
                                                        <td>
                                                            <select onChange={(event)=>this.onSelectChange(i, 'next_step_wait_days', event.target.value)} className="form-control" defaultValue={item.next_step_wait_days}>
                                                                {i>1 && (
                                                                    <option value={2}>After 2 Days</option>
                                                                )}
                                                                {i>0 && (
                                                                    <option value={3}>After 3 Days</option>
                                                                )}
                                                                <option value={7}>After 7 Days</option>
                                                                <option value={15}>After 15 Days</option>
                                                                {i<2 && (
                                                                    <option value={30}>After 30 Days</option>
                                                                )}
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select onChange={(event)=>this.onSelectChange(i, 'contact_type', event.target.value)} className="form-control" defaultValue={item.contact_type}>
                                                                <option value='email_primary'>email {this.props.user.email}</option>
                                                                {i>1 && (
                                                                    <>
                                                                        {this.props.user.backup_email!=='' && (
                                                                            <>
                                                                                <option value='email_backup'>email {this.props.user.backup_email}</option>
                                                                            </>
                                                                        )}
                                                                        {i>2 && this.props.user.phone!=='' && (
                                                                            <>
                                                                                <option value='phone'>text {this.formatPhoneNumber(this.props.user.phone)}</option>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                                
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    {oneLess>i ? (
                                                        <tr>
                                                            <td colSpan={2}>Then, if there is no response:</td>
                                                        </tr>
                                                    ):(
                                                        <>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className="row">
                                                                    <div className="col-md-6" style={{textAlign: 'center', marginBottom: 10}}>
                                                                        {sequenceCount<7 && (
                                                                            <>
                                                                                <button onClick={()=>this.addSequenceStep()} className="btn btn-sm btn-secondary">Add A Step</button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign: 'center', marginBottom: 10}}>
                                                                        {sequenceCount>4 && (
                                                                            <>
                                                                                <button onClick={()=>this.removeSequenceStep()} className="btn btn-sm btn-danger">Remove Last Step</button>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={2}>
                                                                <div className="alert alert-info">At this point, if we have not recieved a response 3 days after our final check-in attempt, we begin to contact your Gatekeepers.</div>
                                                            </td>
                                                        </tr>
                                                        
                                                        </>
                                                    )}
                                                    </React.Fragment>
                                                ):(
                                                    <React.Fragment key={i}>
                                                    <tr> 
                                                        
                                                        <td>
                                                            <select className="form-control" value={item.next_step_wait_days}>
                                                                {i>1 && (
                                                                    <option value={2}>After 2 Days</option>
                                                                )}
                                                                {i>0 && (
                                                                    <option value={3}>After 3 Days</option>
                                                                )}
                                                                <option value={7}>After 7 Days</option>
                                                                <option value={15}>After 15 Days</option>
                                                                {i<2 && (
                                                                    <option value={30}>After 30 Days</option>
                                                                )}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    <tr style={{borderBottom: '1px solid #EFEFEF'}}>
                                                        <td>
                                                            <select className="form-control" value={item.contact_type}>
                                                                <option value='email_primary'>email {this.props.user.email}</option>
                                                                {i>1 && (
                                                                    <>
                                                                        {this.props.user.backup_email!=='' && (
                                                                            <>
                                                                                <option value='email_backup'>email {this.props.user.backup_email}</option>
                                                                            </>
                                                                        )}
                                                                        {i>2 && this.props.user.phone!=='' && (
                                                                            <>
                                                                                <option value='phone'>text {this.formatPhoneNumber(this.props.user.phone)}</option>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    {oneLess>i ? (
                                                        <tr>
                                                            <td>Then, if there is no response:</td>
                                                        </tr>
                                                    ):(
                                                        <>
                                                        <tr>
                                                            <td>
                                                                {sequenceCount<7 && (
                                                                    <button onClick={()=>this.addSequenceStep()} className="btn btn-sm btn-secondary">Add A Step</button>
                                                                )}
                                                                <br /><br />
                                                                {sequenceCount>4 && (
                                                                    <>
                                                                        <button onClick={()=>this.removeSequenceStep()} className="btn btn-sm btn-danger">Remove Last Step</button>
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="alert alert-info">At this point, if we have not recieved a response 3 days after our final check-in attempt, we begin to contact your Gatekeepers.</div>
                                                            </td>
                                                        </tr>
                                                        
                                                        </>
                                                    )}
                                                    </React.Fragment>
                                                )
                                            )
                                            })}
                                    </tbody>
                                </table>
                                </>
                                )}
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}