import React from 'react';
import {toast} from 'react-toastify';
import MyModal from 'components/MyModal';
import AddStorageContainer from './AddStorageContainer';

export default class StorageRecommendations extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            showStorageOptionsModal: false
        }
    }

    componentDidMount(){
        this.getStorageOptions();
    }

    getStorageOptions = () => {
        fetch('https://app.notesforafter.com/operations/storage/getStorageOptions/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, subscription: data.subscription});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    this.setState({loaded: false}, ()=>{
                        toast.error(data.message);
                    })
                }
            }
        })
        .catch(error=>console.log(error));
    }

    render(){
        if(!this.state.loaded){
            return (
                <div style={{marginTop: 15}}>
                    <h4>Add More Storage</h4>
                    <div>
                        <i className="fa fa-spinner fa-spin"></i> Retrieving storage options...
                    </div>
                </div>
            )
        }
        return(
            <div style={{marginTop: 15}}>
                {this.state.subscription.level!==3 && <h4>Add More Storage</h4> }
                <div>
                    {this.state.subscription.level===3 && (
                        <div>
                            <p><i className="fa fa-check"></i> Your {this.state.subscription.name} Subscription provides you with an additional {this.state.subscription.pretty_monthly_mb}mb of space!</p>
                            <p>
                            <button className="btn btn-outline-secondary" onClick={()=>this.setState({showStorageOptionsModal: true})}>Storage Options</button>
                            </p>
                        </div>
                    )}
                    {this.state.subscription.level===2 && (
                        <div>
                            <i className="fa fa-check"></i> Your {this.state.subscription.name} Subscription provides you with an additional {this.state.subscription.pretty_monthly_mb}mb of space! Want more? <button className="btn btn-link" style={{padding: 0, margin: 0}} onClick={()=>this.setState({showStorageOptionsModal: true})}>Click here</button> to upgrade!
                        </div>
                    )}
                    {this.state.subscription.level===1 && (
                        <>
                            <div>
                                <p>Get {this.state.subscription.paidOptions[0].mbPretty}mb of extra space for ${this.state.subscription.paidOptions[0].costPretty}/mo, or {this.state.subscription.paidOptions[1].mbPretty}mb of extra space for ${this.state.subscription.paidOptions[1].costPretty}/mo!</p>
                            </div>
                            <div style={{marginTop: 10}}>
                                <strong>Don't like Subscriptions? You can also add permanent storage space!</strong>
                            </div>
                            <div style={{marginTop: 10}}>
                                <button className="btn btn-outline-secondary btn-small btn-sm" onClick={()=>this.setState({showStorageOptionsModal: true})}>Click here for options!</button>
                            </div>
                        </>
                    )}
                </div>
                {this.state.showStorageOptionsModal && (
                <MyModal autoOpen modalHeaderText='Additional Storage Options' onCloseModal={()=>this.setState({showStorageOptionsModal: false})}>
                    <AddStorageContainer screensize={this.props.screensize} />
                </MyModal>
            )}
            </div>
        )
    }
}