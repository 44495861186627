import React from 'react';
import {toast} from 'react-toastify';
import {Switch, Route} from 'react-router-dom';
import AccountSettings from './AccountSettings';
import CheckInSequence from 'components/checkin/CheckInSequence';
import GatekeepersHome from 'components/gatekeepers/GatekeepersHome';
import FilesHome from 'components/files/FilesHome';
import ReceivedHome from 'components/received/ReceivedHome';

import LogOut from 'components/account/LogOut';
import ConfirmEmailPrompt from './ConfirmEmailPrompt';

export default class AccountHome extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            user: {},
            originalUser: {},
            unsaved: false,
            requireOldPassword: false,
        }
    }

    componentDidMount(){
        this.fetchUserInfo()
    }

    fetchUserInfo = () => {
        fetch('https://app.notesforafter.com/operations/user/fetchUserInfo/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, user: {...data.user}, originalUser: {...data.user}});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    console.log(data);
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }
    /*
    uploadPhoto = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = ()=> {
            const file = input.files[0];
            this.setState({uploadingImage: true, uploadingSize: input.files[0].size});
            const data = new FormData();
            data.append('file', file);
            fetch('https://www.freefunder.com/v2/operations/uploads/?namecon=id&folder=users&dimensions=200x200', {
            method: "POST",
            credentials: 'include',
            body: data
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    let user = this.state.user;
                    user.photo_url = data.endfile+'?ts='+Date.now();
                    this.setState({uploadingImage: false, uploadingSize: 0, user: user});
                    let userToken = JSON.parse(localStorage.getItem('user'));
                    userToken.photo = data.endfile+'?ts='+Date.now();
                    localStorage.setItem('user', JSON.stringify(userToken)); 
                }
                
            })
            .catch( error => console.log(error));
        }
    }
    */

    render(){

        if(!this.state.loaded){
            return (
                <div className="card">
                    <div className="card-body">
                        <i className="fa fa-spinner fa-spin"></i> Loading Account Details...
                    </div>
                </div>
            )
        }

        if(this.state.user.email_confirmed!=='1'){
            return (
                <ConfirmEmailPrompt screensize={this.props.screensize} user={this.state.user} />
            )
        }

        return(
            <Switch>
                <Route exact path='/account/confirmed/backup' render={()=><AccountSettings user={this.state.user} screensize={this.props.screensize} toastIt='Your backup email was successfully verified!' />} />
                <Route exact path='/account/confirmed/email' render={()=><AccountSettings user={this.state.user} screensize={this.props.screensize} toastIt='Your email was successfully verified!' />} />
                <Route exact path='/account' render={()=> <AccountSettings user={this.state.user} screensize={this.props.screensize} />} />
                <Route path='/account/files' render={()=> <FilesHome user={this.state.user} screensize={this.props.screensize} />} />
                <Route path='/account/received' render={()=> <ReceivedHome user={this.state.user} screensize={this.props.screensize} />} />
                <Route path='/account/gatekeepers' render={()=> <GatekeepersHome user={this.state.user} screensize={this.props.screensize} />} />
                <Route path='/account/checkin' render={()=> <CheckInSequence user={this.state.user} screensize={this.props.screensize} />} />   
                <Route path='/account/logout' render={()=><LogOut />} />
                <Route path='/' render={()=> <AccountSettings user={this.state.user} screensize={this.props.screensize} />} />                 
            </Switch>
        )
    }
}