
import React from 'react';
import {toast} from 'react-toastify';
import Spinner from 'components/Spinner';

export default class GatekeeperInvitation extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            user: {},
            loaded: false
        }
    }

    componentDidMount(){
       this.fetchUserInfo();
    }

    fetchUserInfo = () => {
        fetch('https://app.notesforafter.com/operations/user/fetchUserInfo/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, user: {...data.user}, originalUser: {...data.user}});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    console.log(data);
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    render(){

        if(!this.state.loaded){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Just a moment
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <Spinner loadingText="Just a moment..." />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper" style={{width: 'auto'}}>
                <div className="card" style={{marginTop: 10}}>
                    <div className="card-header" style={{margin: -10}}>
                        Example Gatekeeper Invitation
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            
                        <div style={{maxWidth: '800px', margin: '0 auto', background: '#ffffff', padding: '24px'}}>
                            <div style={{fontSize: '18px', marginBottom: '10px', width: '200px'}}><img src="https://app.notesforafter.com/images/logo_white_bg.png" alt="NotesForAfter logo" /></div>
                                <h1>{this.state.user.first} Has Invited You</h1>
                                <div style={{fontSize: '18px', margin: '10px auto', border: '1px solid #EFEFEF', borderRadius: '16px', padding: '10px'}}>{this.state.user.first} {this.state.user.last} wants you to be their trusted Gatekeeper on NotesForAfter.</div>
                                <h4>What is NotesForAfter?</h4>
                                <p>NotesForAfter allows users to send notes, videos, or other files to loved ones and other contacts when they pass away or become incapacitated. This could include personal notes, important financial information, video messages, or anything else that the user wants delivered to their friends, family, business partners, or other contacts upon their death. Files are stored securely and when the user is confirmed to have passed away or become incapacitated, the files are released to the intended recipients.</p>
                                <h4>What is a Gatekeeper?</h4>
                                <p>Gatekeepers are the close, trusted friends of people who have notes, videos, or other files that they want sent to their recipients after they die or become incapacitated. {this.state.user.first} has a Check-In sequence that allows them to verify that everything is okay. If we go all the way through that sequence, reaching out via multiple emails and texts, and still don't hear back from {this.state.user.first}, that's when we reach out to Gatekeepers for final confirmation.</p>
                                <p>This means that other than this email, you will not hear from us again (hopefully) for a very long time! We will only contact you as a Gatekeeper if {this.state.user.first} does not respond to the check-in prompts and we need final confirmation that {this.state.user.first} has passed away or is incapacitated. {this.state.user.first} is trusting you to provide that final confirmation before we release the files to {this.state.user.first}'s intended recipients.</p>
                                <h4>Can {this.state.user.first} count on you as a Gatekeeper?</h4>
                                <p><a href="https://app.notesforafter.com/">Yes, I accept</a></p>
                                <p><a href="https://app.notesforafter.com/">No, I do not accept</a></p>
                                <p>If you want to double-check with {this.state.user.first} before confirming, we encourage you to do so! If you have any other issues or questions, just let us know.</p>
                                <p><strong>Sincerely,</strong><br />NotesForAfter<br /><a href="https://app.notesforafter.com">www.NotesForAfter.com</a></p>
                                <hr />

                                <a class="social" href="https://www.notesforafter.com/">notesforafter.com</a>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a class="social" href="https://www.notesforafter.com/blog/">blog</a>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a class="social" href="https://www.notesforafter.com/contact/">contact us</a>
                                <div style={{marginBottom: '48px'}}></div>
                                <p style={{fontSize: '10px'}}>NotesForAfter.com © 2022 All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


    