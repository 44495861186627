import React from 'react';
import {toast} from 'react-toastify';

export default class GatekeeperSettings extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount(){
        this.fetchSettings();
    }

    fetchSettings = () => {
        fetch('https://app.notesforafter.com/operations/gatekeepers/fetchSettings/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, settings: data.settings});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    onUpdateSettings = (setting, value) => {
        fetch('https://app.notesforafter.com/operations/gatekeepers/updateSetting/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    setting: setting,
                    value: value
                })
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.setState({settings: {...this.state.settings, [setting]: value}});
                    toast.success('Your setting was updated!');
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                        toast.error(data.message);
                    }
                }
            })
            .catch(error=>console.log(error));
    }

    render(){
        if(!this.state.loaded){
            return (
                <div>
                    <i className="fa fa-spinner fa-spin"></i> Loading Settings...
                </div>
            )
        }
        return (
            <div className="card" style={{marginTop: '20px'}}>
                <div className="card-body">
                    <h5>Gatekeeper Settings</h5>
                    <ul>
                        <li style={{margin: '15px auto'}}>Release files to my Recipients when 
                            <select value={this.state.settings.gk_threshold} onChange={(e)=>this.onUpdateSettings('gk_threshold', e.target.value)} className="form-control" style={{display: 'inline', width: '60px', margin: 'auto 5px'}}>
                                {this.state.settings.gk_count===0 && (<option value={0}>0</option>)}
                                {this.state.settings.gk_count>0 && (<option value={1}>1</option>)}
                                {this.state.settings.gk_count>1 && (<option value={2}>2</option>)}
                                {this.state.settings.gk_count>2 && (<option value={3}>3</option>)}
                            </select> of my Gatekeepers have confirmed my death or incapacitation.
                        </li>
                        <li>
                            If any Gatekeeper does not respond after three attempts to reach them, consider their answer to be that my death or incapacitation is  
                            <select value={this.state.settings.gk_default} onChange={(e)=>this.onUpdateSettings('gk_default', e.target.value)} className="form-control" style={{display: 'inline', width: '130px', margin: 'auto 5px'}}>
                                <option value='confirmed'>Confirmed</option>
                                <option value='denied'>Denied</option> by that Gatekeeper.
                            </select>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}