import React from 'react';

import Spinner from 'components/Spinner';
import { NavLink } from 'react-router-dom';

export default class ConfirmSubscription extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            paymentStatus: 'unknown'
        }
    }

    componentDidMount(){
        this.checkForSubscription();
    }

    checkForSubscription = () => {
        fetch('https://app.notesforafter.com/operations/confirm/subscription/', {
            method: "GET",
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((data) => {
            
            if(data.status==='success'){
                this.setState({checked: true, paymentStatus: data.paymentStatus, mbAdded: data.mbAdded});
            }else{
                this.setState({checked: true, showCheckError: true})
            }
        })
        .catch((error)=>{
            console.log(error);
        })   
    }

    render(){
        //let nowPath = window.location.pathname;
        if(!this.state.checked){
            return (
                <Spinner />
            )
        }
        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10}}>
                    <div className="card-header" style={{margin: -10}}>
                        Subscription Confirmation
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.showCheckError ? (
                                <p>Something went wrong when we tried to check on the status of your Subscription. Please <a href="mailto:support@notesforafter.com">email Support</a> and give us the details so we can help!</p>
                            ):(
                                <>
                                    {this.state.paymentStatus==='paid' && (
                                        <>
                                            <h3>Success!</h3>
                                            <p>Your Subscription was created, and you now have {this.state.mbAdded} mb of additional storage space! You can also include up to 20 Recipients for any file.</p>
                                            <p>
                                                <NavLink to="/account/files">Head To Files</NavLink>
                                            </p>
                                        </>
                                    )}
                                    {this.state.paymentStatus==='error' && (
                                        <>
                                            <h3>Something went wrong...</h3>
                                            <p>We don't seem to have a record of your Subscription. Don't panic! If you see the charge on your statement, but do not see the additional storage added, please <a href="mailto:support@notesforafter.com" style={{textDecoration: 'underline'}}>email us</a> with the details so we can look into it for you!</p>
                                            <p>If you do not see the charge on your statement, you will need to <NavLink style={{textDecoration: 'underline'}} to="/account/files">try your purchase again</NavLink>!</p>
                                        </>
                                    )}
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}