import React from 'react';
import {toast} from 'react-toastify';
import FunInput from 'components/FunInput';
import { NavLink } from 'react-router-dom';
import Spinner from './Spinner';

export default class PasswordResetFinish extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            verified: false,
            password: '',
            repeat: '',
            passwordSubmitted: false,
            done: false
        }
    }

    componentDidMount(){
        this.checkCode();
    }

    checkCode = () => {
            fetch('https://app.notesforafter.com/operations/login/checkResetCode/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    code: this.props.code,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({checked: true, verified: true, user: data.user});
                }else{
                    toast.error(data.message);
                    this.setState({checked: true, verified: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
    }

    submitPasswords = () => {
        if(this.state.password!==this.state.repeat){
            toast.error('Your passwords do not match. Type the same password twice, and this will become your new password.')
        }else{
            fetch('https://app.notesforafter.com/operations/login/saveNewPassword/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    code: this.props.code,
                    password: this.state.password
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({done: true});
                }else{
                    toast.error(data.message);
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
        }
    }

    render(){

        if(this.state.done){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Password Reset Completed
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <h4>Password Updated</h4>
                                <div>Your password update was successful. You can now log in using your new password.</div>
                                <div style={{margin: '20px auto', textAlign: 'center'}}>
                                    <NavLink className="btn btn-link" to="/account">Go To Login</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if(!this.state.checked){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Password Reset
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <Spinner loadingText="Just a moment..." />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10}}>
                    <div className="card-header" style={{margin: -10}}>
                        Password Reset
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.verified ? (
                                <>
                                    <div style={{fontSize: '14px', marginBottom: '10px'}}>Hi {this.state.user.first}! Please enter a new password (twice) to update it for your account.</div>
                                    <div style={{marginTop: '20px'}}>
                                        <FunInput type="password" label="Password" value="" onInputChange={(val)=>this.setState({password: val})} /> 
                                        <FunInput type="password" label="Repeat Password" value="" onInputChange={(val)=>this.setState({repeat: val})} /> 
                                        <div style={{textAlign: 'center'}}>
                                            <button disabled={this.state.passwordSubmitted} className="btn btn-outline-secondary" onClick={()=>this.submitPasswords()}>Save Your New Password</button>
                                        </div>
                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                            <NavLink className="btn btn-link" to="/account">Go To Login</NavLink>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div>It looks like the code or link you used is no longer valid. Please try again.</div>
                                    <div style={{margin: '20px auto', textAlign: 'center'}}>
                                        <NavLink className="btn btn-link" to="/account/reset">Start Password Reset</NavLink>
                                    </div>
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}