import React from 'react';
import {toast} from 'react-toastify';

import AccountNav from 'components/account/AccountNav';
import FilesInfo from 'components/files/FilesInfo';

import MyModal from 'components/MyModal';
import UploadOnlyObject from 'components/files/UploadOnlyObject';
import ObjectDetail from 'components/files/ObjectDetail';
import CreateNote from 'components/files/CreateNote';
import AddVault from './AddVault';
import FileListItem from './FileListItem';
import Prompter from 'components/Prompter';
import Alerter from 'components/Alerter';
//import CreateVideo from './CreateVideo';

export default class FilesHome extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            files: {},
            newFileDetailsAdded: false,
            newFileEmail: '',
            newFileFirst: '',
            newFileLast: '',
            vaultId: 0,
            vaults: {},
            passwordChecked: false,
            passwordHint: '',
            password: '',
            passwordFileId: 0,
            passwordFileIndex: 0,
            hasAlert: 'no'
        }
    }

    componentDidMount(){
        this.fetchMyFiles();
    }

    fetchMyFiles = () => {
        fetch('https://app.notesforafter.com/operations/files/fetchMyFiles/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, files: data.files, vaults: data.vaults, fileCount: data.fileCount, mbData: data.mb_data, recipientLimit: data.recipient_limit, hasAlert: data.hasAlert});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    openVault = (vaultId, vaultTitle) => {
        this.setState({vaultId: vaultId, vaultTitle: vaultTitle, addVaultModal: false});
    }

    openSelectionModal = () => {
        this.closeAllExcept('selectionModal');
    }
    openUploadModal = () => {
        this.closeAllExcept('uploadFileModal');
    }
    openCreateNoteModal = () => {
        this.closeAllExcept('createNoteModal');
    }
    openCreateVideoModal = () => {
        this.closeAllExcept('createVideoModal');
    }
    openVaultModal = () => {
        this.closeAllExcept('addVaultModal');
    }
    openPasswordModal = () => {
        this.closeAllExcept('enterPasswordModal');
    }
    closeAllExcept = (openThis) => {
        this.setState({
            objectDetailModal: openThis==='objectDetailModal' ? true : false,
            uploadFileModal:  openThis==='uploadFileModal' ? true : false,
            selectionModal:  openThis==='selectionModal' ? true : false,
            createNoteModal:  openThis==='createNoteModal' ? true : false,
            createVideoModal:  openThis==='createVideoModal' ? true : false,
            addVaultModal:  openThis==='addVaultModal' ? true : false,
            enterPasswordModal:  openThis==='enterPasswordModal' ? true : false,
        })
    }

    openObjectDetail = (objectId, vaultId, objectTitle, fileIndex) => {
        //SEE IF THIS IS PASSWORD PROTECTED//
        //FOR NEWLY UPLOADED OBJECT, WE PASS 999999 AS THE INDEX!!!
        if(fileIndex!==999999 && this.state.files[fileIndex].has_pass===1){
            //THIS IS PASSWORD PROTECTED//
            //window.alert('Password protected.');
            this.setState({passwordHint: this.state.files[fileIndex].hint, passwordFileId: this.state.files[fileIndex].id}, ()=>{
                this.openPasswordModal();
            });
        }else{
            this.setState({objectDetailId: objectId, objectDetailTitle: objectTitle}, ()=>{
                this.setState({
                    objectDetailModal: true,
                    uploadFileModal: false, 
                    selectionModal: false,
                    createNoteModal: false,
                    createVideoModal: false,
                    addVaultModal: false,
                    vaultId: vaultId
                })
            })
        }
    }

    checkPassword = () => {
        fetch('https://app.notesforafter.com/operations/files/checkPass/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                password: this.state.password,
                file_id: this.state.passwordFileId
            })
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({passwordChecked: true}, ()=>{
                    this.fetchMyFiles();
                });
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    handleVaultAdded = (vaultId, vaultTitle) => {
        this.openVault(vaultId, vaultTitle);
        this.fetchMyFiles();
    }

    deleteVault = (vaultId) => {
        fetch('https://app.notesforafter.com/operations/files/deleteVault/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                vault_id: vaultId
            })
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.fetchMyFiles();
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }
    
    render(){

        return(
            <>
            <div className="row">
                <div className="col-sm-5" style={{marginBottom: '10px'}}>
                    <div className="card">
                        <div className="card-body">
                            {this.state.loaded && (
                                <FilesInfo onAddedStorage={()=>this.fetchMyFiles()} user={this.state.user} screensize={this.props.screensize} mbData={this.state.mbData} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    
                        <AccountNav screensize={this.props.screensize} />

                        <div className="card" style={{borderTop: 'none', borderRadius: '0 16px 16px 16px'}}>
                            <div className="card-body">

                                {!this.state.loaded ? (
                                    <div><i className="fa fa-spinner fa-spin"></i> Loading Files...</div>
                                ):(

                                    <>
                                        <div style={{position: 'absolute', top: 10, right: 10 }}>
                                            <button className="btn btn-sm btn-outline-secondary" onClick={()=>this.openSelectionModal()}>Add New</button>
                                        </div>
                                       
                                        {this.state.vaultId===0 ? (
                                            <>
                                                <h4 style={{marginBottom: '25px'}}>My Vaults</h4>
                                            </>
                                        ):(
                                            <>
                                                <button className="btn btn-link" onClick={()=>this.setState({vaultId: 0, vaultTitle: ''})} style={{marginBottom: '10px'}}><i className="fa fa-chevron-left"></i> Back To All Vaults</button>
                                                <h4 style={{marginBottom: '15px'}}><i className="fa fa-archive"></i> {this.state.vaultTitle}</h4>
                                            </>
                                        )}
                                       
                                        {this.state.fileCount===0 ? (
                                            <>
                                                <div>
                                                    You have not added any files yet.
                                                </div>
                                            </>
                                        ):(
                                            <>
                                                {this.state.vaultId===0 ? (
                                                    <>
                                                        {this.state.vaults.map((vault, i)=>{
                                                            return (
                                                                <div className="vault-option" key={i}>
                                                                    <i className="fa fa-archive" onClick={()=>this.openVault(vault.id, vault.title)}></i>
                                                                    <div className="vault-option-title" onClick={()=>this.openVault(vault.id, vault.title)}>{vault.title}</div>
                                                                    <div className="vault-option-files" onClick={()=>this.openVault(vault.id, vault.title)}>{vault.file_count} {vault.file_count==='1' ? 'file' : 'files'}, {vault.mb_count} mb</div>
                                                                    {parseInt(vault.file_count)===0 && (
                                                                        <div className="vault-delete-holder">
                                                                            <button className="btn btn-sm btn-outline-danger" onClick={()=>this.deleteVault(vault.id)}>
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                ):(
                                                    <table className="table table-borderless" style={{marginTop: this.props.screensize!=='small' ? 0 : '15px'}}>
                                                        <tbody>
                                                            {this.state.files.map((item, i)=> {
                                                                let recipientCount = item.recipients.length;
                                                                let exceedsStorage = item.mb_now>this.state.mbData.mb_max ? 'yes': false;
                                                                if(parseInt(this.state.vaultId)!==parseInt(item.vault_id)){
                                                                    return <React.Fragment key={i}></React.Fragment>
                                                                }
                                                                return(
                                                                    <FileListItem 
                                                                        key={i}
                                                                        item={item} 
                                                                        vaultId={this.state.vaultId} 
                                                                        recipientCount={recipientCount} 
                                                                        screensize={this.props.screensize} 
                                                                        exceedsStorage={exceedsStorage}
                                                                        openObjectDetail={(objectId, vaultId, objectTitle)=>this.openObjectDetail(objectId, vaultId, objectTitle, i)}
                                                                    />
                                                                )
                                                            })}
                                                            
                                                        </tbody>
                                                    </table>
                                                )}
                                            </>
                                        )}

                                        {this.state.hasAlert==='yes' ? (
                                            <Alerter />
                                        ):(
                                            <Prompter />
                                        )}
                                        
                                    </>
                                )}
                            </div>
                        </div>
                    
                </div>
            </div>

            

            {this.state.selectionModal && (
                <MyModal autoOpen modalHeaderText='Add New File' onCloseModal={()=>this.setState({selectionModal: false})}>
                    <div>
                        <div style={{marginBottom: 30}}>What would you like to add?</div>
                        <div style={{margin: '10px auto'}}>
                            <button className="btn btn-sm btn-fun-transparent-full" onClick={()=>this.openCreateNoteModal()}><i className="fa fa-edit"></i> Create New Note</button>
                        </div>
                        <div style={{margin: '10px auto'}}>
                            <button className="btn btn-sm btn-fun-transparent-full" onClick={()=>this.openUploadModal()}><i className="fa fa-upload"></i> Upload A File</button>
                        </div>
                        <div style={{margin: '10px auto'}}>
                            <button className="btn btn-sm btn-fun-transparent-full" onClick={()=>this.openVaultModal()}><i className="fa fa-archive"></i> Add A New Vault</button>
                        </div>
                    </div>
                </MyModal>
            )}

            {this.state.uploadFileModal && (
                <MyModal autoOpen modalHeaderText='Upload A New File' onCloseModal={()=>this.setState({uploadFileModal: false})}>
                    <UploadOnlyObject mbAvail={this.state.mbData.mb_avail} mbTotal={this.state.mbData.mb_total} vaults={this.state.vaults} selectedVault={this.state.vaultId} onUploadComplete={(id, vaultId, fileTitle)=>this.openObjectDetail(id, vaultId, fileTitle, 999999)} />
                </MyModal>
            )}

            {this.state.enterPasswordModal && (
                <MyModal autoOpen modalHeaderText='Password Required' onCloseModal={()=>this.setState({enterPasswordModal: false, passwordChecked: false, passwordFileId: 0, passwordFileIndex: 0, passwordHint: '', password: ''})}>
                    {!this.state.passwordChecked ? (
                        <>
                            <p>A password is required to open this file. Once your password is verified, the file will be unlocked for the remainder of your logged-in session.</p>
                            <p>Password Hint: {this.state.passwordHint}</p>
                            <p>
                                <input type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={(e)=>this.setState({password: e.target.value})} />
                                <br />
                                <button className="btn btn-outline-secondary" onClick={()=>this.checkPassword()}>Submit</button>
                            </p>
                        </>
                    ):(
                        <div>
                            <h5>Success!</h5>
                            <p>Your file has been unlocked and will remain accessible to you for the rest of your session.</p>
                        </div>
                    )}
                    
                </MyModal>
            )}

            {this.state.addVaultModal && (
                <MyModal autoOpen modalHeaderText='Add A New Vault' onCloseModal={()=>this.setState({addVaultModal: false})}>
                    <AddVault onVaultAdded={(vaultId, vaultTitle)=>this.handleVaultAdded(vaultId, vaultTitle)} />
                </MyModal>
            )}

            {this.state.objectDetailModal && (
                <MyModal autoOpen modalHeaderText={`Settings: ${this.state.objectDetailTitle}`} onCloseModal={()=>this.setState({objectDetailModal: false}, ()=>this.fetchMyFiles())}>
                    <ObjectDetail vaults={this.state.vaults} objectId={this.state.objectDetailId} onDeleteFile={()=>this.setState({objectDetailModal: false}, ()=>this.fetchMyFiles())} />
                </MyModal>
            )}

            {this.state.createNoteModal && (
                <MyModal autoOpen modalHeaderText='Create A New Note' onCloseModal={()=>this.setState({createNoteModal: false})}>
                    <CreateNote vaults={this.state.vaults} selectedVault={this.state.vaultId} onNoteCreated={(id, vaultId)=>this.openObjectDetail(id, vaultId, 'New Note', 999999)} />
                </MyModal>
            )}

            </>
        )
    }
}