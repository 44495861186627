import React from 'react';

export default class FileListItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    render(){
        let item = this.props.item;
        let vaultId = this.props.vaultId;
        return (
            <React.Fragment>
                <tr className="file-list-item" style={{borderBottom: '1px solid #EFEFEF'}}>
                    {this.props.screensize!=='small' && (
                        <td>
                            {(item.type==='image' || item.type==='JPG' || item.type==='PNG') && <i className="fa fa-image"></i>}
                            { (item.type==='MP4' || item.type==='MOV') && <i className="fa fa-play"></i>}
                            {(item.type==='PDF' || item.type==='pdf') && <i className="fa fa-file-o"></i>}
                            {(item.type==='CRYPTO' || item.type==='HC' || item.type==='TC') && <i className="fa fa-lock"></i>}
                        </td>
                        )
                    }

                        <td onClick={()=>this.props.openObjectDetail(item.id, vaultId, item.title)}>
                        <div>
                            {item.title}
                        </div>
                        <div style={{fontSize: 12}}>
                            {item.has_pass===1 ? (
                                <span>This is password protected.</span>
                            ):(
                                <>
                                {this.props.exceedsStorage ? (
                                    <span style={{color: '#FF0000'}}>File exceeds storage limit. Will not be sent.</span>
                                ):(
                                    <>
                                        {this.props.recipientCount>0 ? (
                                            <> Added {item.date_added} for {this.props.recipientCount>1 ? `${this.props.recipientCount} people` : `${item.recipients[0].first} ${item.recipients[0].last}`}</>
                                        ):(
                                            <span style={{color: '#FF0000'}}>No recipient yet.</span>
                                        )}
                                    </>
                                )}
                                </>
                            )}
                            
                            
                        </div>
                    </td>
                    <td>
                        <button className="btn btn-link" onClick={()=>this.props.openObjectDetail(item.id, vaultId, item.title)}>
                            <i className="fa fa-gear"></i>
                        </button>
                    </td>
                    {this.props.screensize!=='small' && (
                    <td>
                        {item.has_pass===0 ? (
                            <a className="btn btn-link" target="_blank" rel="noreferrer noopener" href={`https://app.notesforafter.com/files/open/?fc=${item.code}`}>
                                <i className="fa fa-eye"></i>
                            </a>
                        ):(
                            <button className="btn btn-link">
                                <i className="fa fa-lock"></i>
                            </button>
                        )}
                        
                    </td>
                    )}
                </tr>
            </React.Fragment>
        )
    }
}