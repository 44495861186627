
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class UploadOnlyObject extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uploadingFile: false,
            uploadingSize: 0,
            isError: false,
            errorMessage: '',
            successMessage: '',
            vaults: this.props.vaults,
            selectedVault: this.props.selectedVault
        }
    }

    updateVaultId = (newVaultId) => {
        this.setState({selectedVault: newVaultId});
    }

    doUpload = (data) => {
        axios.request({
            method: "post", 
            url: "https://app.notesforafter.com/operations/files/uploadOnlyFile/", 
            data: data, 
            withCredentials: true,
            onUploadProgress: (p) => {
              //console.log(p); 
              this.setState({
                  fileprogress: (p.loaded / p.total)*100
              })
            }
        }).then (data => {
            console.log(data);
            if(data.data.status==='success'){
                this.setState({uploadingFile: false, uploadingSize: 0, fileprogress: 100}, ()=>{
                    this.props.onUploadComplete(data.data.newId, this.state.selectedVault, 'New Item');
                });
            }else{
                this.setState({uploadingFile: false, uploadingSize: 0, isError: true, errorMessage: data.data.message});
            }
        })
        .catch( error => console.log(error));
    }

    uploadFile = () => {
        if(this.state.selectedVault===0 || this.state.selectedVault==='0'){
            toast.error('Please select which Vault you would like to upload this file to!');
            return false;
        }
        //const input = document.createElement('input');
        const input = document.createElement('input');
        let vid = document.createElement('video');
        let canUpload = false;
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*,video/*,audio/*,.pdf,.doc,.docx,.zip,.xls,.xlsx,.crypto,.txt,.csv,.hc,.tc');
        input.click();
        input.onchange = ()=> {
            const file = input.files[0];
            this.setState({uploadingFile: true, uploadingSize: input.files[0].size});
            const data = new FormData();
            data.append('vaultId', this.state.selectedVault);
            data.append('file', file);
            let file_bytes = file.size;
            let file_kb = file_bytes/1000;
            let file_mb = file_kb/1000;
            if(file.type.match('video.*')){
                let fileURL = URL.createObjectURL(file);
                vid.src = fileURL;
                vid.ondurationchange = function(){
                    data.append('duration', vid.duration);
                    canUpload = true;
                }
                data.append('isVideo', 'yes');
            }else{
                data.append('isVideo', 'no');
                canUpload = true;
            }

            if(file_mb>this.props.mbAvail){
                this.setState({uploadingFile: false, isError: true, errorMessage: `It looks like the file you are trying to upload would push you over your storage limit! Your file is ${file_mb}mb and the space you still have available is only ${this.props.mbAvail}mb.`});
            }else{
                if(canUpload){
                    this.doUpload(data);
                }else{
                    setTimeout(()=>{
                        this.doUpload(data)
                    }, 1000);
                }
            }
        }
    }

    render(){
        return(
            <>
                <h5>Choose a File to Upload</h5>
                <div>You can upload images, documents, videos, and even zip files! If you are uploading something with sensitive information, be sure to protect yourself by password-protecting the files before you upload. </div>
                <div>
                    <select className="form-control" style={{margin: '15px auto'}} value={this.state.selectedVault} onChange={(e)=>this.updateVaultId(e.target.value)}>
                        {(this.state.vaults.length<2 || this.state.selectedVault===0 || this.state.selectedVault==='0') && <option value={0}>Select Which Vault</option>}
                        {this.state.vaults.map((item, i)=> {
                            return (
                                <option key={i} value={item.id}>{item.title}</option>
                            )
                        })}
                    </select>
                </div>
                <div style={{textAlign: 'center', marginTop: 15}}>
                    <button className="btn btn-sm btn-outline-secondary" disabled={this.state.uploadingFile ? true : undefined} onClick={()=>this.uploadFile()}>{this.state.uploadingFile ? 'Uploading...' : 'Upload Your File' }</button>    
                </div>
                {this.state.uploadingFile && (
                    <div style={{margin: '15px auto'}}>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: this.state.fileprogress+'%'}} aria-valuenow={this.state.fileprogress} aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                    </div>
                )}    
                {this.state.fileprogress===100 && (
                    <div style={{marginTop: 15, textAlign: 'center'}}>Just a moment...</div>
                )}
                {this.state.isError && (
                    <div style={{marginTop: 15}}>
                        <div className="alert alert-danger">{this.state.errorMessage}</div>
                    </div>
                )}    
            </>
        )
    }
}

