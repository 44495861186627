import React from 'react';

export default class CheckinInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: true
        }
    }

    render(){
        return (
            <div>
                {this.props.screensize==='small' && (
                    <div style={{position: 'absolute', top: '10px', right: '10px'}}>
                        <button className="btn btn-default" onClick={()=>this.props.closeInfo()}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                )}
                <h4>Set Up Check-Ins</h4>
                <p>We will check in with you based on the schedule you decide. The first two check-ins will be via email sent to your main email address. If you have a Backup Email confirmed, then starting with the third check-in attempt you can select your Backup Email for the check-in attempt. This is great in case you lose access to your main email account. Starting with the fourth attempt, we can send you a text message.</p>
                <p>You can have between four and seven Check-In Sequence items. Once we have made attempts based on each of those items, if we still have not received any answer from you we will begin contacting your <a href="https://app.notesforafter.com/account/gatekeepers">Gatekeepers</a>. Gatekeepers can then confirm your death or incapacitation so that we can release files to your intended Recipients.</p>
                <p>For each sequence item, select how many days you want us to wait in between attempts to reach you.</p>
            </div>
        )
    }
}