import React from 'react';
import {toast} from 'react-toastify';

import MyEditor from 'components/MyEditor';



export default class CreateNote extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uploadingFile: false,
            uploadingSize: 0,
            vaults: this.props.vaults,
            selectedVault: this.props.selectedVault
        }
    }

    handleSaveUpdate = (html) => {
        if(this.state.selectedVault>0){
            if(window.confirm('When you click OK we will convert your note to a PDF and store the encrypted file with your other items. Keep in mind, we do not save any of your written text, we only save the encrypted PDF.\n\nIf you need to make changes later, you will have to delete the file and start again. If you want to double-check before converting this to a PDF, just click the Cancel button.')){
                fetch('https://app.notesforafter.com/operations/files/createNewPDF/', {
                    method: "POST",
                    credentials: 'include',
                    body: JSON.stringify({ 
                        html: html,
                        vaultId: this.state.selectedVault
                    })
                })
                .then((response)=>response.json())
                .then((data)=>{
                    if(data.status==='success'){
                        toast.success('PDF created.');
                        this.props.onNoteCreated(data.newId, this.state.selectedVault);
                    }else{
                        if(data.extra==='logged_out'){
                            localStorage.removeItem('token');
                            window.location.reload();
                        }else{
                            toast.error(data.message);
                        }
                    }
                })
                .catch(error=>console.log(error));
            }
        }else{
            window.alert('Be sure to choose which Vault you want this note stored in!');
        }
    }
    updateVaultId = (newVaultId) => {
        this.setState({selectedVault: newVaultId});
    }

    render(){
        return(
            <>
                <div>
                    <select className="form-control" style={{margin: '15px auto'}} value={this.state.selectedVault} onChange={(e)=>this.updateVaultId(e.target.value)}>
                        {(this.state.vaults.length<2 || this.state.selectedVault===0 || this.state.selectedVault==='0') && <option value={0}>Select Which Vault</option>}
                        {this.state.vaults.map((item, i)=> {
                            return (
                                <option key={i} value={item.id}>{item.title}</option>
                            )
                        })}
                    </select>
                </div>
                <MyEditor onSaveUpdate={(html)=>this.handleSaveUpdate(html)} limited saveButtonText='Create PDF' saveButtonColor='outline-secondary' />
            </>
        )
    }
}

