import React from 'react';
import {toast} from 'react-toastify';

export default class Gatekeeper extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            sending: false
        }
    }

    componentDidMount(){
        
    }

    sendInvitation = () => {
        if(window.confirm('It is highly recommended that you reach out to this person to let them know they should expect this email. If you do not, they may be confused about what this is and mark it as spam or ignore it.\n\nAfter you have reached out to them, click OK to send the email.')){
            this.setState({sending: true});
            fetch('https://app.notesforafter.com/operations/invitations/send/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    gk_id: this.props.gk.id,
                })
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.props.onSendInvitation();
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                        toast.error(data.message);
                        this.setState({sending: false})
                    }
                }
            })
            .catch(error=>console.log(error));
        }
    }

    render(){
        return(
            <div className="card" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                {this.props.showSort && (
                <div style={{margin: '-10px 0 -10px -10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%', borderRight: '1px solid #CCC'}}>
                        <div style={{borderBottom: '1px solid #CCC', flexGrow: 1}}>
                            <button disabled={this.props.currentPosition===1} onClick={()=>this.props.onSort('up')} className="btn btn-sm btn-outline-secondary btn-order-sort"><i className="fa fa-caret-up"></i></button>
                        </div>
                        <div style={{flexGrow: 1}}>
                            <button disabled={this.props.totalCount===this.props.currentPosition} onClick={()=>this.props.onSort('down')} className="btn btn-sm btn-outline-secondary btn-order-sort"><i className="fa fa-caret-down"></i></button>
                        </div>
                    </div>
                </div>
                )}
                <div style={{flexGrow: 1, padding: '0 .5rem'}}>
                    <div className="gatekeeper-name" style={{marginBottom: 4}}>{this.props.gk.first} {this.props.gk.last}</div>
                    <div className="gatekeeper-email" style={{marginBottom: 4}}>{this.props.gk.email}</div>
                    {this.props.gk.phone!=='' && (<div className="gatekeeper-phone">{this.props.gk.phone_pretty}</div>)}
                </div>

                <div style={{margin: '-10px -10px -10px 0', width: this.props.screensize==='large' ? '100px' : '50px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {this.props.gk.invited_ago==='never' ? (
                                <button disabled={this.state.sending} onClick={()=>this.sendInvitation()} className="btn btn-sm btn-outline-secondary" style={{width: '90%'}}>
                                    <i className="fa fa-envelope"></i> {this.props.screensize==='large' && ('Invite')}
                                </button>
                            ):(
                                <>
                                    {this.props.gk.confirmed_ago==='never' ? (
                                        <>
                                            {this.props.gk.date_rejected==='0000-00-00 00:00:00' ? (
                                                <>
                                                    {this.props.gk.can_reinvite ? (
                                                        <button disabled={this.state.sending} onClick={()=>this.sendInvitation()} className="btn btn-sm btn-outline-secondary" style={{width: '90%'}}>
                                                            <i className="fa fa-envelope"></i> {this.props.screensize==='large' && ('Invite')}
                                                        </button>
                                                    ):(
                                                        <span style={{color: '#6c757d'}}><i className="fa fa-check"></i> Invited</span>
                                                    )}
                                                
                                                </>
                                            ):(
                                                <span style={{color: '#6c757d'}}><i className="fa fa-exclamation"></i> Declined</span>
                                            )}
                                            
                                        </>
                                    ):(
                                        <>
                                            <i className="fa fa-check"></i> Accepted
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                        <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {this.props.gk.date_rejected==='0000-00-00 00:00:00' ? (
                            <button onClick={()=>this.props.onDelete()} className="btn btn-sm btn-outline-danger" style={{width: '90%'}}>
                                <i className="fa fa-trash"></i> {this.props.screensize==='large' && ('Delete')}
                            </button>
                            ):(
                                <>{this.props.gk.rejected_ago} ago</>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}