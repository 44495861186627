import React from 'react';

export default class ProgressBar extends React.Component {

    _isMounted = false;

    constructor(props){
        
        super(props);
        this.state = {
            percent: this.props.percent || 0,
            rate: this.props.rate || 20
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.increaseBar();
    }
    componentWillUnmount(){
        this._isMounted = false;
    }

    increaseBar = () => {
        setInterval(()=>{
            if(this.state.percent<100){
                if(this._isMounted){
                    this.setState({percent: this.state.percent+1});
                }
            }
        }, this.state.rate)
    }

    render(){
        return (
            <div className="progress">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={this.state.percent} aria-valuemin="0" aria-valuemax="100" style={{width: `${this.state.percent}%`}}></div>
            </div>
        )
    }
}