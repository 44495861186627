import React from 'react';
import {toast} from 'react-toastify';

import AccountNav from 'components/account/AccountNav';
import AddGatekeeper from 'components/gatekeepers/AddGatekeeper';
import Gatekeeper from 'components/gatekeepers/Gatekeeper';
import GatekeeperSettings from './GatekeeperSettings';
import GatekeepersInfo from './GatekeepersInfo';
import MyModal from 'components/MyModal';

export default class GatekeepersHome extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            gatekeepers: [],
            showRejections: false,
            showInfo: this.props.screensize==='small' ? false : true
        }
    }

    componentDidMount(){
        this.fetchGatekeepers();
    }

    fetchGatekeepers = () => {
        fetch('https://app.notesforafter.com/operations/user/fetchGatekeepers/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, gatekeepers: data.gatekeepers, count: data.count, anyConfirmed: data.anyConfirmed, anyInvited: data.anyInvited, hasRejections: data.hasRejections || false});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    handleSort = (gk_id, nowSort, direction) => {
        if( (nowSort>1 && direction==='up') || (nowSort<this.state.count && direction==='down')){
            fetch('https://app.notesforafter.com/operations/gatekeepers/shiftSortOrder/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    gk_id: gk_id,
                    direction: direction
                })
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.fetchGatekeepers();
                    toast.success('The order of your Gatekeepers was successfully updated!');
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                        toast.error(data.message);
                    }
                }
            })
            .catch(error=>console.log(error));
        }
    }

    handleDelete = (gk_id) => {
        if(window.confirm('Are you sure you want to completely delete this Gatekeeper?')){
            fetch('https://app.notesforafter.com/operations/gatekeepers/deleteGatekeeper/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    gk_id: gk_id,
                })
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    this.fetchGatekeepers();
                }else{
                    if(data.extra==='logged_out'){
                        localStorage.removeItem('token');
                        window.location.reload();
                    }else{
                        toast.error(data.message);
                    }
                }
            })
            .catch(error=>console.log(error));
        }
    }
    
    render(){

        return(

            <div className="row">
                <div className="col-sm-5" style={{marginBottom: '10px'}}>
                    <div className="card">
                        <div className="card-body">  
                            {this.state.loaded && (
                                <>
                                    {this.state.showInfo ? (
                                        <GatekeepersInfo user={this.state.user} closeInfo={()=>this.setState({showInfo: false})} screensize={this.props.screensize} />
                                    ):(
                                      <>
                                        <div style={{position: 'absolute', top: '10px', right: '10px'}}>
                                            <button className="btn btn-sm" onClick={()=>this.setState({showInfo: true})}>
                                                <i className="fa fa-question-circle-o"></i> Info
                                            </button>
                                        </div>
                                        <h5>Gatekeepers</h5>
                                      </>  
                                    )}
                                    
                                </>
                                
                            )}           
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    
                        <AccountNav screensize={this.props.screensize} />

                        <div className="card" style={{borderTop: 'none'}}>
                            <div className="card-body">
                                {!this.state.loaded ? (
                                    <div><i className="fa fa-spinner fa-spin"></i> Loading Gatekeepers...</div>
                                ):(
                                    <>
                                    <div style={{position: 'absolute', top: 10, right: 10 }}>
                                        <button className="btn btn-sm btn-outline-secondary" onClick={()=>this.setState({addingGatekeeper: true})}>Add Gatekeeper</button>
                                    </div>
                                    <h4 style={{marginBottom: '25px'}}>Gatekeepers</h4>
                                    {this.state.count>0 ? (
                                        <div>
                                            {this.state.gatekeepers.map((gk, i)=>{
                                                if(gk.date_rejected==='0000-00-00 00:00:00'){
                                                    return (
                                                        <Gatekeeper 
                                                            showSort={this.state.count>1 ? true : false} 
                                                            onSort={(direction)=>this.handleSort(gk.id, gk.sort_order, direction)} 
                                                            currentPosition={i+1} 
                                                            totalCount={this.state.count} 
                                                            onDelete={()=>this.handleDelete(gk.id)} 
                                                            gk={gk} 
                                                            key={i} 
                                                            screensize={this.props.screensize}
                                                            onSendInvitation={()=>this.fetchGatekeepers()}
                                                        />
                                                    )
                                                }else{
                                                    return <React.Fragment key={i}></React.Fragment>
                                                }
                                                })
                                            }
                                            {!this.state.anyConfirmed && (
                                                <div>
                                                    {!this.state.anyInvited && (
                                                        <div className="alert alert-info">Make sure you click the "Invite" button for your Gatekeeper when you're ready for them to receive your Invitation Email.</div>
                                                    )}
                                                </div>
                                            )}
                                            {this.state.hasRejections && (
                                                <div>
                                                <button className="btn btn-link" onClick={()=>this.setState({showRejections: !this.state.showRejections})}> {!this.state.showRejections ? 'Show ' : 'Hide '}Declined Gatekeepers</button>
                                                {this.state.showRejections && (
                                                    <div style={{margin: '10px auto'}}>
                                                    {this.state.gatekeepers.map((gk, i)=>{
                                                        if(gk.date_rejected!=='0000-00-00 00:00:00'){
                                                            return (
                                                                <Gatekeeper 
                                                                    showSort={false} 
                                                                    onSort={(direction)=>this.handleSort(gk.id, gk.sort_order, direction)} 
                                                                    currentPosition={i+1} 
                                                                    totalCount={this.state.count} 
                                                                    onDelete={()=>this.handleDelete(gk.id)} 
                                                                    gk={gk} 
                                                                    key={i} 
                                                                    screensize={this.props.screensize}
                                                                />
                                                            )
                                                        }else{
                                                            return <></>
                                                        }
                                                        })
                                                    }
                                                    </div>
                                                )}
                                                </div>
                                            )}

                                            <div style={{marginTop: 15}}>
                                                <a href="/examples/gatekeeperInvitation" target="_blank" rel="noopener"><i className="fa fa-question-circle"></i> What does the Gatekeeper Invitation look like?</a>
                                            </div>
                                        </div>
                                    ):(
                                        <div>You have not added any Gatekeepers yet.</div>
                                    )}
                                    {this.state.count>0 && this.state.anyConfirmed && (
                                        <GatekeeperSettings gkCount={this.state.count} />
                                    )}
                                    </>
                                )}
                            </div>
                        </div>
                    
                </div>
                {this.state.addingGatekeeper && (
                    <MyModal autoOpen modalHeaderText='Add A Gatekeeper' onCloseModal={()=>this.setState({addingGatekeeper: false})}>
                        <AddGatekeeper onAddGatekeeper={()=>this.setState({addingGatekeeper: false}, ()=>this.fetchGatekeepers())} />
                    </MyModal>
                )}
            </div>

            
        )
    }

}