import React from 'react';
import {toast} from 'react-toastify';
import FunInput from 'components/FunInput';

export default class RequestFileLinks extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            newLinksSent: false,
            resendEmailAddress: '',
        }
    }

    resendLink = () => {
        this.setState({newLinksSending: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/recipients/resendFileLink/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    email: this.state.resendEmailAddress,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({newLinksSent: true, newLinksSending: false});
                }else{
                    this.setState({newLinksSending: false})
                    toast.error(data.message);
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        })  
    }

    render(){

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10, maxWidth: 450}}>
                    <div className="card-header" style={{margin: -10}}>
                        Request File Links Via Email
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <>
                                {!this.state.newLinksSent ? (
                                    <>
                                        <div>If you have received a notification that you are the Recipient of files on NotesForAfter, you can request links to access them here. Please enter your email address below.</div>
                                        <div style={{textAlign: 'center', margin: 15}}>
                                            <FunInput type="text" label="Email Address" value={this.state.resendEmailAddress} onInputChange={(val)=>this.setState({resendEmailAddress: val})} />
                                            <div style={{margin: 10}}>
                                                <button disabled={this.state.newLinksSending} onClick={()=>this.resendLink()} className="btn btn-success">Send File Link(s)</button>
                                            </div>
                                        </div>
                                    </>
                                ):(
                                    <div>
                                        <div className="alert alert-info">If there are files that have been sent to the email address you provided, we have sent a new email to you. You should receive it within the next few minutes.</div>
                                    </div>
                                )}
                            </>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}