import Spinner from 'components/Spinner';
import React from 'react';
import {toast} from 'react-toastify';


export default class AddStorageContainer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            loadedFree: false,
            tab: 'subs'
        }
    }

    componentDidMount(){
        this.getSubOptions();
    }

    getSubOptions = () => {
        fetch('https://app.notesforafter.com/operations/storage/getSubOptions/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, subscription: data.subscription, permanentOptions: data.permanentOptions});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    this.setState({loaded: false}, ()=>{
                        toast.error(data.message);
                    })
                }
            }
        })
        .catch(error=>console.log(error));
    }

    switchToFree = () => {
        this.setState({tab: 'free'}, ()=>{
            this.getFreeOptions();
        })
    }

    getFreeOptions = () => {
        fetch('https://app.notesforafter.com/operations/storage/getFreeOptions/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loadedFree: true, available: data.available, completed: data.completed, agUserId: data.agUserId});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    this.setState({loaded: false}, ()=>{
                        toast.error(data.message);
                    })
                }
            }
        })
        .catch(error=>console.log(error));
    }

    render(){
        if(!this.state.loaded){
            return <Spinner />
        }
        return(
            <div style={{marginTop: 15}}>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button onClick={()=>this.setState({tab: 'subs'})} className={this.state.tab==='subs' ? 'nav-link active' : 'nav-link'} style={{marginRight: 2}}>
                            {this.props.screensize!=='small' ? 'Subscriptions' : 'Subscribe'}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button onClick={()=>this.setState({tab: 'perm'})} className={this.state.tab==='perm' ? 'nav-link active' : 'nav-link'} style={{marginRight: 2}}> 
                            {this.props.screensize!=='small' ? 'Permanent Storage' : 'Permanent'}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button onClick={()=>this.switchToFree()} className={this.state.tab==='free' ? 'nav-link active' : 'nav-link'} style={{marginRight: 2}}>
                            Free
                        </button>
                    </li>
                </ul>
                <div id="storage-option-content" style={{margin: '10px auto'}}>
                    {this.state.tab==='subs' && (
                        <div>
                            { (this.state.subscription.level==='3' || this.state.subscription.level===3 || this.state.subscription.level===2 || this.state.subscription.level==='2') ? (
                                <>
                                    {this.state.subscription.isEnding===0 ? (
                                        <>
                                             <h4>You're Subscribed!</h4>
                                             <p>You have our {this.state.subscription.name} Subscription plan, providing you with an extra {this.state.subscription.pretty_monthly_mb}mb of storage and up to 20 Recipients for each file you store. If you would like to add more Permanent Storage, click on the Permanent Storage or the Free tab!</p>
                                        </>
                                    ):(
                                        <>
                                            <h4>Your Subscription Is Ending</h4>
                                             <p>Your Subscription has been canceled and will not renew automatically. As of now you will lose your {this.state.subscription.name} Plan benefits on {this.state.subscription.endDate}. If you wish to renew the Subscription before you lose your benefits, click on the button to manage it! If you would like to add more Permanent Storage, click on the Permanent Storage or the Free tab.</p>
                                        </>
                                    )}
                                   
                                    <p>
                                        <a className="btn btn-outline-secondary" href="https://app.notesforafter.com/stripe/manageSubscription/">Manage Subscription</a>
                                    </p>
                                </>
                            ):(
                                <>
                                    <h4>Affordable Subscription Options</h4>
                                    <div>With any Subscription, you'll have significantly more storage space, as well as being able to add up to 20 Recipients to any file. Choose the six month billing option for the best price!</div>
                                    {this.state.subscription.paidOptions.map((option, i)=>{
                                        return (
                                            <div key={i} style={{marginTop: 10, border: '1px solid #EFEFEF', borderRadius: '5px 5px 5px 5px', padding: 8}}>
                                                <h5>{option[0].name} Subscription</h5>
                                                <p>Get an additional <strong>{option[0].mbPretty}</strong> megabytes of space!</p>
                                                
                                                    
                                                    {option.map((thisOption, n)=>{
                                                        if(this.state.subscription.subscription_plan_id===thisOption.id){
                                                            return (
                                                                <p key={n}>
                                                                    ${thisOption.costPretty}/month - <i className="fa fa-check"></i> You currently have this plan!
                                                                </p>
                                                            )
                                                        }
                                                        return (
                                                            <p key={n}>${thisOption.costPretty}/month - <a href={`/checkout/stripe/subscription/?sp_id=${thisOption.id}`} style={{textDecoration: 'underline'}}>${thisOption.cost_per_bill} {this.props.screensize!=='small' ? 'billed' : ''} every {thisOption.months_per_bill} month{thisOption.months_per_bill>1 ? 's' : ''}</a></p>
                                                        )
                                                    })}
                                                    
                                                
                                            </div>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    )}
                    {this.state.tab==='perm' && (
                        <div>
                            <h4>Storage For Life!</h4>
                            <div>Add more storage space and keep it for life. With any purchase of permanent storage space, you will also automatically be able to add up to 20 Recipients to any file.</div>
                            {this.state.permanentOptions.map((option, i)=>{
                                return (
                                    
                                    <div key={i} style={{marginTop: 10, border: '1px solid #EFEFEF', borderRadius: '5px 5px 5px 5px', padding: 8}}>
                                        <h5>{option.megabytes}mb of Additional Space</h5>
                                        <p>Get another {option.description}, and add up to 20 Recipients for each file!</p>
                                        <p><a href={`/checkout/stripe/permanent/?po_id=${option.id}`} style={{textDecoration: 'underline'}}>${option.cost} for {option.megabytes}mb</a> ({option.cost_per_mb})</p>
                                    </div>
                                    
                                )
                            })}
                        </div>
                    )}
                    {this.state.tab==='free' && (
                        <div>
                            {this.state.loadedFree ? (
                                <div>
                                    {this.state.showAdgate ? (
                                        <div>
                                            <div><button className="btn btn-link" onClick={()=>this.setState({showAdgate: false})}>Back To Storage Options</button></div>
                                            <iframe title="Adgate Offerwall" style={{width: '100%', height: '400px'}} src={`https://wall.adgaterewards.com/oKqYpw/${this.state.agUserId}`}></iframe>
                                        </div>
                                    ):(
                                        <>
                                            {this.state.available.length>0 && (
                                                <div>
                                                    <h5>Get Free Permanent Storage</h5>
                                                    {this.state.available.map((task, i)=>{
                                                        return (
                                                            <div key={i} style={{marginTop: 10, border: '1px solid #EFEFEF', borderRadius: '5px 5px 5px 5px', padding: 8}}>
                                                                <h5>Get <strong>{task.mb}mb</strong> - {task.title}</h5>
                                                                <p>{task.description}</p>
                                                            </div>
                                                        )
                                                    })}
                                                    <div key={99} style={{marginTop: 10, border: '1px solid #EFEFEF', borderRadius: '5px 5px 5px 5px', padding: 8}}>
                                                        <h5>Get <strong>100+mb</strong> - Surveys and Offers</h5>
                                                        <p>AdGate Media has an Offer Wall which allows you to complete surveys, try games, or complete other offer types. For any option, the amount of extra storage you can earn is clearly visible. Many offers confirm instantly!</p>
                                                        <p><button className="btn btn-link" onClick={()=>this.setState({showAdgate: true})}>See Available Surveys and Offers</button></p>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.completed.length>0 && (
                                                <div style={{marginTop: 20}}>
                                                    <h5>Completed Items</h5>
                                                    {this.state.completed.map((task, i)=>{
                                                        return (
                                                            <div key={i+100} style={{marginTop: 10, border: '1px solid #EFEFEF', borderRadius: '5px 5px 5px 5px', padding: 8}}>
                                                                <div style={{fontSize: 18}}><i className="fa fa-check"></i> {task.title} ({task.mb}mb)</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            ):(
                                <div>
                                    <i className="fa fa-spinner fa-spin"></i> Loading Free Options...
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}