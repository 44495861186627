import React from 'react';
import {toast} from 'react-toastify';
import FunInput from 'components/FunInput';
import Spinner from 'components/Spinner';
import { NavLink } from 'react-router-dom';

export default class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            loginSubmitted: false,
            tab: 'login',
            loginEmail: '',
            loginPassword: '',
            registerFirst: '',
            registerLast: '',
            registerEmail: '',
            registerPassword: '',
            token: localStorage.getItem('token') || null,
            betaCodeChecking: false,
            betaCodeAccepted: false,
            betaCode: '',
            betaEmail: '',
            doBetaRequest: false,
            requestingBetaAccess: false,
            requestedBetaAccess: false
        }
    }

    componentDidMount(){
        this.checkWithToken();
    }

    checkWithToken = () => {
        if(this.state.token){
            fetch('https://app.notesforafter.com/operations/user/checkToken/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    token: this.state.token
                })
            })
            .then((response) => response.json())
            .then((data) => {
                this.setState({checked: true});
                if(data.status==='success'){
                    if(data.loggedIn==='yes'){
                        //SET THE TOKEN
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('user', JSON.stringify(data.user));
                        this.props.onTokenSet();
                    }
                }else{
                    if(data.status==='error'){
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                    }
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
        }else{
            this.setState({checked: true});
        }
    }
    onBetaCodeSubmit = e => {
        e.preventDefault();
        this.setState({betaCodeChecking: true});
        fetch('https://app.notesforafter.com/operations/register/checkBetaCode/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                code: this.state.betaCode,
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                this.setState({betaCodeAccepted: true, betaCodeChecking: false})
            }else{
                toast.error(data.message);
                this.setState({betaCodeChecking: false, betaCodeAccepted: false, betaCode: ''});
            }
        })
        .catch((error)=>{
            console.log(error);
        })   
    }

    onRequestBeta = e => {
        e.preventDefault();
        this.setState({requestingBetaAccess: true});
        fetch('https://app.notesforafter.com/operations/register/requestBetaAccess/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                email: this.state.betaEmail,
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                fetch('https://app.notesforafter.com/operations/send/emailQ/');
                this.setState({requestedBetaAccess: true, requestingBetaAccess: false})
            }else{
                toast.error(data.message);
                this.setState({requestingBetaAccess: false, requestedBetaAccess: false, betaCode: '', betaEmail: ''});
            }
        })
        .catch((error)=>{
            console.log(error);
        })   
    }

    onLoginSubmit = e => {
        e.preventDefault();
        this.setState({loginSubmitted: true});
        fetch('https://app.notesforafter.com/operations/login/checkCredentials/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                email: this.state.loginEmail,
                password: this.state.loginPassword
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                this.props.onTokenSet();
            }else{
                toast.error(data.message);
                this.setState({loginSubmitted: false})
            }
        })
        .catch((error)=>{
            console.log(error);
        })   
    }

    onRegisterSubmit = e => {
        e.preventDefault();
        this.setState({registerSubmitted: true});
        fetch('https://app.notesforafter.com/operations/register/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                email: this.state.registerEmail,
                password: this.state.registerPassword,
                first: this.state.registerFirst,
                last: this.state.registerLast,
                autoConfirm: false
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                fetch('https://app.notesforafter.com/operations/send/emailQ/');
                localStorage.setItem('token', data.token);
                toast.success('Registration successful!');
                localStorage.setItem('user', JSON.stringify(data.user));
                this.props.onTokenSet();
            }else{
                toast.error(data.message);
                this.setState({registerSubmitted: false})
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    render(){
        //let nowPath = window.location.pathname;
        if(!this.state.checked){
            return (
                <Spinner />
            )
        }
        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10}}>
                    <div className="card-header" style={{margin: -10}}>
                        Please Log In or Register
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <div style={{fontSize: '14px', margin: '10px auto'}}>Please log in or register using your email and password below.</div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button onClick={()=>this.setState({tab: 'login'})} className={this.state.tab==='login' ? 'nav-link active' : 'nav-link' } style={{backgroundColor: '#fff'}}>Log In</button>
                                </li>
                                <li className="nav-item">
                                <button onClick={()=>this.setState({tab: 'register'})} className={this.state.tab==='register' ? 'nav-link active' : 'nav-link' } style={{backgroundColor: '#fff'}}>Register</button>
                                </li>
                            </ul>
                            <div style={{marginTop: '20px'}}>
                                {this.state.tab==='login' ? (
                                    <form onSubmit={this.onLoginSubmit}>
                                        <FunInput type="text" label="Email Address" value="" onInputChange={(val)=>this.setState({loginEmail: val})} />
                                        <FunInput type="password" label="Password" value="" onInputChange={(val)=>this.setState({loginPassword: val})} />
                                        <p style={{fontSize: '12px'}}>By accessing this website, you are agreeing to the <a href="https://www.notesforafter.com/terms" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>Terms of Use</a> and <a href="https://www.notesforafter.com/privacy" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>Privacy Policy</a></p>
                                        <div style={{textAlign: 'center'}}>
                                            <button disabled={this.state.loginSubmitted} className="btn btn-primary" type="submit">Log In</button>
                                        </div>
                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                            <NavLink to="/account/reset" className="btn btn-link">Forgot Your Password?</NavLink>
                                        </div>
                                    </form>
                                ):(
                                    <>
                                    {!this.state.betaCodeAccepted ? (
                                        <div style={{height: this.state.doBetaRequest ? '220px' : '290px'}}>
                                            {!this.state.doBetaRequest ? (
                                                <>
                                                    <p>We are currently in Private Beta Test. Registration will become available upon completion of testing.</p>
                                                    <p>If you have been given a Beta Test invitation code, please enter it below.</p>
                                                    <form onSubmit={this.onBetaCodeSubmit}>
                                                        <FunInput type="text" label="Beta Test Code" value="" onInputChange={(val)=>this.setState({betaCode: val})} />
                                                        <div style={{textAlign: 'center'}}>
                                                            <button disabled={this.state.betaCodeChecking} className="btn btn-primary" type="submit">{this.state.betaCodeChecking ? 'Checking...' : 'Check Code'}</button>
                                                        </div>
                                                    </form>
                                                    <p style={{marginTop: 20}}>
                                                        <button className="btn btn-link" onClick={()=>this.setState({doBetaRequest: true})}>I want a Beta Test invitation code!</button>
                                                    </p>
                                                </>
                                            ):(
                                                <>
                                                    {!this.state.requestedBetaAccess ? (
                                                        <>
                                                            <p>To join our waitlist for Beta Access, please enter your email below.</p>
                                                            <form onSubmit={this.onRequestBeta}>
                                                                <FunInput type="text" label="Email Address" value="" onInputChange={(val)=>this.setState({betaEmail: val})} />
                                                                <div style={{textAlign: 'center'}}>
                                                                    <button disabled={this.state.requestingBetaAccess} className="btn btn-primary" type="submit">{this.state.requestingBetaAccess ? 'Joining...' : 'Join Waitlist'}</button>
                                                                </div>
                                                            </form>
                                                        </>
                                                    ):(
                                                        <>
                                                            <p>Thanks! We have added you to the Beta Test waitlist, we will contact you when an opportunity becomes available!</p>
                                                        </>
                                                    )}
                                                    
                                                </>
                                            )}
                                            
                                        </div>
                                    ):(
                                        <div>
                                            <div style={{margin: '10px auto'}}>You're in! Please register below.</div>
                                            <form onSubmit={this.onRegisterSubmit}>
                                                <FunInput type="text" label="First Name" value="" onInputChange={(val)=>this.setState({registerFirst: val})} />
                                                <FunInput type="text" label="Last Name" value="" onInputChange={(val)=>this.setState({registerLast: val})} />
                                                <FunInput type="text" label="Email Address" value="" onInputChange={(val)=>this.setState({registerEmail: val})} />
                                                <FunInput type="password" label="Password" value="" onInputChange={(val)=>this.setState({registerPassword: val})} />
                                                <p style={{fontSize: '12px'}}>By accessing this website, you are agreeing to the <a href="https://www.notesforafter.com/terms" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>Terms of Use</a> and <a href="https://www.notesforafter.com/privacy" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>Privacy Policy</a></p>
                                                <div style={{textAlign: 'center'}}>
                                                    <button disabled={this.state.registerSubmitted} className="btn btn-primary" type="submit">Register</button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                    
                                    
                                    
                                    
                                    
                                    </>
                                )}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}