import React from 'react';

const Spinner = (props) => {
    return (
        <div id="preloader">
            <div id="loaderText">
                {props.loadingText || 'Loading...' }
            </div>
            <div id="loader"></div>
        </div>
    );
};

export default Spinner;