import React from 'react';
import { NavLink } from 'react-router-dom';

export default class GatekeepersInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: true
        }
    }

    render(){
        return (
            <div>
                {this.props.screensize==='small' && (
                    <div style={{position: 'absolute', top: '10px', right: '10px'}}>
                        <button className="btn btn-default" onClick={()=>this.props.closeInfo()}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                )}
                <h5>About Gatekeepers</h5>
                <p>Your Gatekeepers are your most trusted contacts, and the people who would know if something happened to you. After we have attempted to check in with you based on all of the steps you've outlined in the <NavLink style={{textDecoration: 'underline'}} to="/account/checkin">Check-In Sequence</NavLink>, we will contact your Gatekeepers. They will be asked if you are okay. They will have the opportunity to confirm that everything is fine, or that you are incapacitated, or that you have passed away.</p>
                <p>Gatekeepers will be contacted in the order they appear in your list. You can decide how many Gatekeepers must confirm your death or incapacitation in order for us to release files to your Recipients.</p>
            </div>
        )
    }
}