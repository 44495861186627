import React from 'react';
import {toast} from 'react-toastify';
import { NavLink } from 'react-router-dom';
import Spinner from 'components/Spinner';
import FunInput from 'components/FunInput';

export default class FileAccess extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            verified: false,
            tos: false,
            newLinksSent: false,
            resendEmailAddress: '',
            presignedURL: '',
            haveLink: false,
            gettingLink: false,
            url_expires: 0
        }
    }

    componentDidMount(){
        this.checkFileLink();
    }

    checkFileLink = () => {
            fetch('https://app.notesforafter.com/operations/recipients/checkFileLink/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    code: this.props.code,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({checked: true, verified: true, recipient: data.recipient, owner: data.owner, fileCode: data.fileCode, fileType: data.fileType, fileTitle: data.fileTitle, loggedIn: data.loggedIn});
                }else{
                    toast.error(data.message);
                    this.setState({checked: true, verified: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
    }

    resendLink = () => {
        this.setState({newLinksSending: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/recipients/resendFileLink/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    email: this.state.resendEmailAddress,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({newLinksSent: true, newLinksSending: false});
                }else{
                    this.setState({newLinksSending: false})
                    toast.error(data.message);
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        })  
    }

    getPresignedURL = () => {
        if(this.state.terms){
            this.setState({gettingLink: true}, ()=>{
                fetch('https://app.notesforafter.com/operations/recipients/getPresignedURL/', {
                    method: "POST",
                    credentials: 'include',
                    body: JSON.stringify({ 
                        code: this.state.fileCode,
                    })
                })
                .then((response) => response.json())
                .then((data) => {
                    if(data.status==='success'){
                        this.setState({gettingLink: false, haveLink: true, presignedURL: data.url, url_expires: data.expires_in_seconds});
                    }else{
                        toast.error(data.message);
                        this.setState({gettingLink: false, haveLink: false});
                    }
                })
                .catch((error)=>{
                    console.log(error);
                }) 
            })
        }
    }

    render(){

        if(!this.state.checked){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Retrieving Your File
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <Spinner loadingText="Just a moment..." />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10, maxWidth: 450}}>
                    <div className="card-header" style={{margin: -10}}>
                        Your File {this.state.verified && <>From {this.state.owner.first}</>}
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.verified ? (
                                <>
                                    <div style={{marginBottom: '10px'}}>
                                        <div>
                                            <p>Hi {this.state.recipient.first},</p>
                                            <p>{this.state.owner.first} has left a file for you. It's important to understand that <strong>NotesForAfter does not know the contents</strong>, and we are not responsible for them. We take user privacy very seriously, so when {this.state.owner.first} uploaded the file, we securely stored it so that it could be delivered to you, but we can not make any claims about what it is or whether it is safe. Only access this file if you trust {this.state.owner.first} and accept any and all consequences for accessing it.</p>
                                            <p>Please check the box to indicate your agreement with our Terms of Use and Privacy Policy.</p>
                                            <p><input type="checkbox" onClick={()=>this.setState({terms: !this.state.terms})} /> I understand NotesForAfter is not responsible for the contents of this file, and I agree to the <a href="https://app.notesforafter.com/terms" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="https://app.notesforafter.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                                            {this.state.terms && (
                                                <div style={{margin: '20px auto', textAlign: 'center'}}>
                                                    <a className="btn btn-success" href={`https://app.notesforafter.com/files/open/?fc=${this.state.fileCode}`} target="_blank" rel="noopener noreferrer">Access File</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                    
                                    {!this.state.loggedIn && (
                                        <>
                                            {!this.state.recipient.hasAccount ? (
                                                <>
                                                    {this.state.terms && (
                                                        <div>
                                                            <h4>Interested In Your Own Account?</h4>
                                                            <div>NotesForAfter makes it easy for you to share notes, videos, or other files with loved ones and significant people in your life when you pass away, and right now you can get an account for free. Create an account today!</div>
                                                            <div style={{marginTop: '20px'}}>
                                                                <div style={{margin: '20px auto', textAlign: 'center'}}>
                                                                    <NavLink className="btn btn-outline-secondary" to="/account">Register Now</NavLink>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ):(
                                                <div>
                                                    <h4>Access Your Account</h4>
                                                    <div>To access your account, please log in.</div>
                                                    <div style={{marginTop: '20px'}}>
                                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                                            <NavLink className="btn btn-outline-secondary" to="/account">Go To Login</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                   
                                    
                                    </div>
                                </>
                            ):(
                                <>
                                    {!this.state.newLinksSent ? (
                                        <>
                                            <div>It looks like the code or link you used is no longer valid. There may be a typo, or the link may have expired. To get a new link sent to you, please enter your email address below.</div>
                                            <div style={{textAlign: 'center', margin: 15}}>
                                                <FunInput type="text" label="Email Address" value={this.state.resendEmailAddress} onInputChange={(val)=>this.setState({resendEmailAddress: val})} />
                                                <div style={{margin: 10}}>
                                                    <button disabled={this.state.newLinksSending} onClick={()=>this.resendLink()} className="btn btn-success">Send New Link(s)</button>
                                                </div>
                                            </div>
                                        </>
                                    ):(
                                        <div>
                                            <div className="alert alert-info">If there are files that have been sent to the email address you provided, we have sent a new email to you. You should receive it within the next few minutes.</div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}