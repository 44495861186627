import React from 'react';
import {toast} from 'react-toastify';

import AccountNav from 'components/account/AccountNav';
import AccountInfo from 'components/account/AccountInfo';

import FunInput from 'components/FunInput';

export default class AccountSettings extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            user: this.props.user,
            originalUser: this.props.user,
            unsaved: false,
            requireOldPassword: false
        }
    }

    componentDidMount(){
        this.fetchUserInfo();
        if(this.props.toastIt){
            toast.success(this.props.toastIt);
        }
    }

    fetchUserInfo = () => {
        this.setState({loaded: false});
        fetch('https://app.notesforafter.com/operations/user/fetchUserInfo/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, user: {...data.user}, originalUser: {...data.user}, subscription: data.subscription});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    saveChanges = () => {
        fetch('https://app.notesforafter.com/operations/user/saveChanges/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                user: this.state.user
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                this.setState({loaded: true, user: {...data.user, newPassword: ''}, originalUser: {...data.user, newPassword: ''}, requireOldPassword: false, unsaved: false, showNewPassInput: false}, ()=>{
                    toast.success('Your settings were updated!');
                });
                if(data.sent_email===true || data.sent_backup_email===true){
                    fetch('https://app.notesforafter.com/operations/send/emailQ/', {});
                    toast.info('We sent a link to confirm your email address!');
                }
            }else{
                toast.error(data.message);
            }
            
        })
        .catch( error => console.log(error));
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          var intlCode = (match[1] ? '+1 ' : '');
          return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
      }
 
    onInputChange = (input, val) => {
       let {user} = this.state;
       user[input] = val;
       let unsaved = false;
       if(val!==this.state.originalUser[input]){
           unsaved = true;
       }
       let requireOldPass = this.state.requireOldPassword;
       if(input==='newPassword' && this.state.user.getOldPass){
           requireOldPass = true;
       }
       if(input==='newPassword' && val===''){
            requireOldPass = false;
       }
       console.log(val);
       console.log(this.state.originalUser[input]);
       console.log(unsaved);
       this.setState({user: user, unsaved: unsaved, requireOldPassword: requireOldPass});
    }

    /*
    toggleEmailSetting = (input) => {
       let {user} = this.state;
       user[input] = this.state.user[input]==='1' ? '0':'1';
       this.setState({user: user}, ()=>{
           this.updateEmailSettings(input, user[input]);
       });
       console.log('update '+input+' to '+user[input]);
    }
    */

    render(){
        return(

            <div className="row">
                <div className="col-sm-5" style={{marginBottom: '10px'}}>
                    <div className="card">
                        <div className="card-body">
                            {this.state.loaded && (
                                <AccountInfo subscription={this.state.subscription} onPhoneVerified={()=>this.fetchUserInfo()} user={this.state.user} screensize={this.props.screensize} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    
                        <AccountNav screensize={this.props.screensize} />

                        <div className="card" style={{borderTop: 'none'}}>
                            <div className="card-body">
                                {!this.state.loaded ? (
                                    <div><i className="fa fa-spinner fa-spin"></i> Loading Account Details...</div>
                                ):(
                                    <>
                                    <h4 style={{marginBottom: '25px'}}>Account Settings</h4>
                                    {this.state.unsaved && (
                                        <div className="alert alert-info" style={{marginBottom: '15px'}}>Don't forget to save your changes when you're done!</div>
                                    )}
                                    <FunInput label='First Name' addedClasses='big-input' value={this.state.user.first} onInputChange={(val)=>this.onInputChange('first', val)} />
                                    <FunInput label='Last Name' addedClasses='big-input' value={this.state.user.last} onInputChange={(val)=>this.onInputChange('last', val)} />
                                    <FunInput label='Email Address' addedClasses='big-input' value={this.state.user.email} onInputChange={(val)=>this.onInputChange('email', val)} />
                                    <FunInput label='Backup Email' addedClasses='big-input' value={this.state.user.backup_email} onInputChange={(val)=>this.onInputChange('backup_email', val)} />
                                    <FunInput label='Phone' addedClasses='big-input' value={this.formatPhoneNumber(this.state.user.phone)} onInputChange={(val)=>this.onInputChange('phone', val)} />
                                    {this.state.showNewPassInput ? (
                                        <FunInput label='New Password (if changing)' type='password' addedClasses='big-input' value={this.state.user.newPassword} onInputChange={(val)=>this.onInputChange('newPassword', val)} />
                                    ):(
                                        <button className="btn btn-sm btn-outline-secondary" style={{margin: '5px 0 20px 0'}} onClick={()=>this.setState({showNewPassInput: true})}>Click To Set A New Password</button>
                                    )}
                                    {this.state.requireOldPassword && (
                                        <FunInput label='Current Password' type='password' addedClasses='big-input' value={this.state.user.oldPassword} onInputChange={(val)=>this.onInputChange('oldPassword', val)} />
                                    )}

                                    {this.state.unsaved && (
                                        <div style={{margin: '15px auto', textAlign: 'center'}}>
                                            <button onClick={()=>this.saveChanges()} className="btn btn-secondary pulse-button">Save Changes</button>
                                        </div>
                                    )}
                                    </>
                                )}
                                
                            </div>
                        </div>
                    
                </div>
            </div>


            

        )
    }
}