import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.autoOpen
    };
  }

  /*
  componentWillReceiveProps(nextProps){
    if(nextProps.autoOpen!==this.props.autoOpen){
      this.setState({modal: nextProps.autoOpen});
    }
  }
  */

  static getDerivedStateFromProps(props, state) {
    if (props.autoOpen !== state.modal) {
      return {
        modal: props.autoOpen
      };
    }
    return null;
  }

  toggle = () => {
    let oldState = this.state;
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    if(this.props.onToggle!==undefined){
      this.props.onToggle();
    }
    if(this.props.onCloseModal!==undefined){
      if(oldState.modal){
        this.props.onCloseModal();
      }
    }
  }

  onConfirm = () => {
    //DO SOMETHING HERE//
    this.toggle();
    this.props.onConfirm();
  }

  onCancel = () => {
    this.toggle();
    this.props.onCancel();
  }

  render() {
    return (
      <div>
        {!this.props.autoOpen ? (
          <Button outline={this.props.outline} color={this.props.buttonColor} onClick={this.toggle}>{this.props.triggerText}</Button>
        ):(
          <div></div>
        )}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} style={{borderBottom: 'none'}}>
            { this.props.modalHeaderIcon !== undefined ? (
              <span>
                <i className={this.props.modalHeaderIcon}></i> {this.props.modalHeaderText}
              </span>
            ):(
              <span>{this.props.modalHeaderText}</span> 
            )}      
          </ModalHeader>
          <ModalBody>
            {this.props.children}
          </ModalBody>
          <ModalFooter style={{borderTop: 'none'}}>
            { this.props.hasConfirm ? (
              <Button color="primary" onClick={this.onConfirm}>{this.props.confirmButtonText || 'Confirm'}</Button>
            ) : (
              <span></span>
            )}
            { this.props.hasCancel ? (
              <Button outline color="danger" onClick={this.onCancel}>{this.props.cancelButtonText || 'Cancel'}</Button>
            ):(
              <span></span>
            )}
            
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default MyModal;