import React from 'react';
import {toast} from 'react-toastify';
import { NavLink } from 'react-router-dom';
import Spinner from 'components/Spinner';

export default class ConfirmStatus extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            verified: false,
            code: this.props.code
        }
    }

    componentDidMount(){
        this.doConfirmStatus();
    }

    doConfirmStatus = () => {
            fetch('https://app.notesforafter.com/operations/confirmUserStatus/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    code: this.props.code,
                    status: this.props.status
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({checked: true, verified: true, gatekeeper: data.gatekeeper, forUser: data.forUser});
                }else{
                    toast.error(data.message);
                    this.setState({checked: true, verified: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
    }

    render(){

        if(!this.state.checked){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Confirming Status
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <Spinner loadingText="Just a moment..." />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10, maxWidth: 450}}>
                    <div className="card-header" style={{margin: -10}}>
                        Status Confirmed
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.verified ? (
                                <>
                                    <div style={{marginBottom: '10px'}}>
                                        <div>
                                            <h3><i className="fa fa-check"></i> Thank You For Confirming</h3>
                                            {
                                                <>
                                                    {this.props.status==='okay' && (
                                                        <>
                                                        <p>Hi {this.state.gatekeeper.first}, thank you for confirming that {this.state.forUser.first} is okay, this is great news! We will continue to reach out to {this.state.forUser.first} and his or her other Gatekeepers for further confirmation.</p>
                                                        <p><strong>It would be a good idea to reach and let {this.state.forUser.first} know he or she should log into his or her NotesForAfter Account as soon as possible.</strong></p>
                                                        </>
                                                    )}
                                                    {this.props.status==='incapacitated' && (
                                                        <p>Hi {this.state.gatekeeper.first}, thank you for confirming that {this.state.forUser.first} has been incapacitated. We are very sorry to hear this, but will continue with our verifications so that we can release files that {this.state.forUser.first} intended to have sent to Recipients in the event of his or her incapacitation.</p>
                                                    )}
                                                    {this.props.status==='passedAway' && (
                                                        <p>Hi {this.state.gatekeeper.first}, thank you for confirming that {this.state.forUser.first} has passed away. We are very sorry to hear this, but will continue with our verifications so that we can release files that {this.state.forUser.first} intended to have sent to Recipients in the event of his or her death.</p>
                                                    )}
                                                </>
                                            }
                                            
                                            <p>If you need to change this confirmation, please select the appropriate option below.</p>
                                            <a href={`/gk/confirmStatus/okay/${this.props.code}`} className={this.props.status==='okay' ? 'confirmation-option selected' : 'confirmation-option'}>
                                                {this.state.forUser.first} is okay
                                            </a>
                                            <a href={`/gk/confirmStatus/incapacitated/${this.props.code}`} className={this.props.status==='incapacitated' ? 'confirmation-option selected' : 'confirmation-option'}>
                                                {this.state.forUser.first} has become incapacitated
                                            </a>
                                            <a href={`/gk/confirmStatus/passedAway/${this.props.code}`} className={this.props.status==='passedAway' ? 'confirmation-option selected' : 'confirmation-option'}>
                                                {this.state.forUser.first} has passed away
                                            </a>
                                        </div>
                                    </div>
                                    <hr />
                                    {this.state.gatekeeper.hasAccount ? (
                                    <div style={{marginTop: '30px'}}>
                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                            <NavLink className="btn btn-link" to="/account">Go To Login</NavLink>
                                        </div>
                                    </div>
                                    ):(
                                        <div style={{marginTop: '30px'}}>
                                            <h4>Want Your Own Account?</h4>
                                            <div>NotesForAfter makes it easy for you to share notes, videos, or other files with loved ones and significant people in your life when you pass away, and right now you can get an account for free.</div>
                                            <div style={{marginTop: '20px'}}>
                                                <div style={{margin: '20px auto', textAlign: 'center'}}>
                                                    <NavLink className="btn btn-link" to="/account">Register Now</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ):(
                                <>
                                    <div>It looks like the code or link you used is no longer valid. Please try again. If you continue to have trouble, please email <a href="mailto:support@notesforafter.com">support@notesforafter.com</a>.</div>
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}