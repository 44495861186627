import React from 'react';

export default class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            newsletterModal: false,
            first: '',
            last: '',
            email: ''
        }
    }

    updateNewsletterInput = (val, field) => {
        this.setState({[field]: val})
    }

    render(){
        return (
            <React.Fragment>
            <div id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-4" style={{margin: '10px auto'}}>
                            <a href="https://www.notesforafter.com/how-it-works" target="_blank" rel="noreferrer">How It Works</a><br/>
                            <a href="https://www.notesforafter.com/#faq" target="_blank" rel="noreferrer">Frequently Asked Questions</a><br/>
                        </div>
                        <div className="col-sm-6 col-md-4" style={{margin: '10px auto'}}>
                            
                            <a href="https://www.notesforafter.com/contacts">Contact Us</a><br/>
                            <a href="/terms">Terms of Use</a> | <a href="/privacy" style={{marginLeft: 0}}>Privacy Policy</a>
                        </div>
                        
                        <div className="col-sm-12 col-md-4">
                            <div>
                                <img src="https://app.notesforafter.com/images/logo_blue_bg_text_only.png" style={{height: 50}} alt="Notes For After logo" />
                            </div>
                            <div>Don't Take It To The Grave</div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}