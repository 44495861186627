import React from 'react';
import {toast} from 'react-toastify';

import MyModal from 'components/MyModal';
import FunInput from 'components/FunInput';
import AddStorageContainer from 'components/addStorage/AddStorageContainer';

export default class AccountInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: true,
            showEmailConfirmationSent: false,
            showPhoneConfirmationSent: false,
            showStorageOptionsModal: false,
            phoneCode: ''
        }
    }

    hitEmailQ = () => {
        fetch('https://app.notesforafter.com/operations/send/emailQ/', {       
            credentials: 'include',
        })
        .catch( error => console.log(error));
    }

    sendConfirmation = (type) => {
        fetch('https://app.notesforafter.com/operations/user/sendConfirmation/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                type: type
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                if(type==='phone'){
                    this.setState({showPhoneConfirmationSent: true});
                }else{
                    this.setState({showEmailConfirmationSent: true}, this.hitEmailQ());
                }
            }else{
                toast.error(data.message);
            }
            
        })
        .catch( error => console.log(error));
    }

    verifyPhone = () => {
        fetch('https://app.notesforafter.com/operations/user/verifyPhone/', {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({ 
                code: this.state.phoneCode
            })
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                toast.success('Your phone number was successfully verified!');
                this.props.onPhoneVerified();
            }else{
                toast.error(data.message);
            }
            
        })
        .catch( error => console.log(error));
    }

    render(){
        return (
            <div>
                <h4>Account Information</h4>
                {this.props.subscription.status==='none' && (
                    <div>
                        You are currently on our Free plan. Upgrade now to get 5-100,000mb of extra storage space for as little as $3/month!
                    </div>
                )}
                {this.props.subscription.status==='ended' && (
                    <div>
                        Your Subscription ended on {this.props.subscription.end_date_pretty}. Renew now to get up to 100,000mb of storage space! 
                    </div>
                )}
                {this.props.subscription.status==='ending' && (
                    <div>
                        Your Subscription has ended and your Premium Membership will end on {this.props.subscription.ends_on_pretty}. Renew now to keep your extra storage space! 
                    </div>
                )}
                {this.props.subscription.status==='current' && (
                    <div>
                        <i className="fa fa-check"></i> Your Subscription is current and will renew on or around {this.props.subscription.renews_on_pretty}. 
                    </div>
                )}

                <div style={{margin: '15px auto'}}>
                    <button className="btn btn-link" style={{paddingLeft: 0, textDecoration: 'underline'}} onClick={()=>this.setState({showStorageOptionsModal: true})}>See Additional Storage Options</button>
                </div>

                {this.props.screensize==='large' && (
                   <hr />
                )}
                

                {this.props.user.email_confirmed!=='1' ? (
                    <div className="alert alert-danger">
                        You have not yet confirmed your main Email Address.<br /><br />
                        <button className="btn btn-sm btn-outline-secondary" onClick={()=>this.sendConfirmation('email')}>Confirm Now</button>
                    </div>
                ):(
                    <div className='large-screen-only'>
                        <i className="fa fa-check"></i> Main Email Address confirmed.<br /><br />
                    </div>
                )}
                {this.props.user.backup_confirmed!=='1' ? (
                    <div className="alert alert-danger">
                        You have not yet confirmed your Backup Email Address.<br /><br />
                        <button className="btn btn-sm btn-outline-secondary" onClick={()=>this.sendConfirmation('backup_email')}>Confirm Now</button>
                    </div>
                ):(
                    <div className='large-screen-only'>
                        <i className="fa fa-check"></i> Backup Email Address confirmed.<br /><br />
                    </div>
                )}
                {this.props.user.phone_confirmed!=='1' ? (
                    <div className="alert alert-danger">
                        You have not yet confirmed your Phone Number.<br /><br />
                        <button className="btn btn-sm btn-outline-secondary" onClick={()=>this.sendConfirmation('phone')}>Confirm Now</button>
                    </div>
                ):(
                    <div className='large-screen-only'>
                        <i className="fa fa-check"></i> Phone Number confirmed.<br /><br />
                    </div>
                )}


            {this.state.showEmailConfirmationSent && (
                <MyModal autoOpen modalHeaderText='Confirmation Email Sent' onCloseModal={()=>this.setState({showEmailConfirmationSent: false})}>
                    <div style={{textAlign: 'center'}}>
                        <p>We have sent an email verification link to you. Please check your email and click on the link!</p>
                    </div>
                </MyModal>
            )}

            {this.state.showPhoneConfirmationSent && (
                <MyModal autoOpen modalHeaderText='Confirmation Text Sent' onCloseModal={()=>this.setState({showPhoneConfirmationSent: false})}>
                    <div>
                        <div>We have texted a code to your phone number. Please enter the code below to verify your phone number!</div>
                        <div style={{textAlign: 'center', marginTop: 15}}>
                            <FunInput label='Enter Texted Code' value={this.state.phoneCode} onInputChange={(val)=>this.setState({phoneCode: val})} />
                            <button className="btn btn-outline-secondary" onClick={()=>this.verifyPhone()} style={{marginTop: 10}}>Submit Code</button>
                        </div>
                    </div>
                </MyModal>
            )}

            {this.state.showStorageOptionsModal && (
                <MyModal autoOpen modalHeaderText='Additional Storage Options' onCloseModal={()=>this.setState({showStorageOptionsModal: false})}>
                    <AddStorageContainer screensize={this.props.screensize} />
                </MyModal>
            )}
            </div>
        )
    }
}