import React from 'react';
import {toast} from 'react-toastify';
import { NavLink } from 'react-router-dom';
import Spinner from './Spinner';

export default class CheckinLanding extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            checked: false,
            verified: false
        }
    }

    componentDidMount(){
        this.doCheckin();
    }

    doCheckin = () => {
            fetch('https://app.notesforafter.com/operations/user/doCheckin/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    code: this.props.code,
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({checked: true, verified: true, user: data.user});
                }else{
                    toast.error(data.message);
                    this.setState({checked: true, verified: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })   
    }

    render(){

        if(!this.state.checked){
            return(
                <div className="login-form-wrapper">
                    <div className="card login-panel" style={{marginTop: 10}}>
                        <div className="card-header" style={{margin: -10}}>
                            Checking You In
                        </div>
                        <div className="card-body">
                            <div className="card-text">
                                <Spinner loadingText="Just a moment..." />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10}}>
                    <div className="card-header" style={{margin: -10}}>
                        Checking In
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.verified ? (
                                <>
                                    <div style={{fontSize: '14px', marginBottom: '10px'}}>Hi {this.state.user.first}, you're all checked in! Your next check-in email will be sent in {this.state.user.nextSendDays} days. If you'd like to log in and access your account, use the link below.</div>
                                    <div style={{marginTop: '20px'}}>
                                        <div style={{margin: '20px auto', textAlign: 'center'}}>
                                            <NavLink className="btn btn-link" to="/account">Go To Login</NavLink>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div style={{marginTop: 20}}>It looks like the code or link you used is no longer valid. Please log into your account to complete your check in.</div>
                                    <div style={{margin: '20px auto', textAlign: 'center'}}>
                                        <NavLink className="btn btn-link" to="/account">Go To Login</NavLink>
                                    </div>
                                </>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}