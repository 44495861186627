import React from 'react';
import {toast} from 'react-toastify';

export default class ConfirmEmailPrompt extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            sending: false,
            sent: false
        }
    }

    sendConfirmation = () => {
        this.setState({sending: true}, ()=>{
            fetch('https://app.notesforafter.com/operations/user/sendConfirmation/', {
                method: "POST",
                credentials: 'include',
                body: JSON.stringify({ 
                    type: 'email',
                })
            })
            .then((response) => response.json())
            .then((data) => {
                if(data.status==='success'){
                    this.setState({sending: false, sent: true}, ()=>{
                        //MAKE SURE THE EMAIL_Q RUNS//
                        fetch('https://app.notesforafter.com/operations/send/emailQ/');
                    });
                }else{
                    toast.error(data.message);
                    this.setState({sending: false, sent: false});
                }
            })
            .catch((error)=>{
                console.log(error);
            })
        })
               
    }

    render(){

        return(
            <div className="login-form-wrapper">
                <div className="card login-panel" style={{marginTop: 10}}>
                    <div className="card-header" style={{margin: -10}}>
                        Confirm Your Email Address
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            {this.state.sent ? (
                                <div>
                                    <h5>Success!</h5>
                                    <p>We sent another confirmation email to your email account on file. Please click the link to confirm your email address!</p>
                                </div>
                            ):(
                                <>
                                    <div style={{marginBottom: 10}}>We sent a link to your email account on file. Please click on the link to confirm your email address in order to access your account!</div>
                                    <div style={{marginBottom: 20}}>If you don't see the previous email from us, you can re-send the link below.</div>
                                    <div style={{marginBottom: 10}}>
                                        <buttonn className="btn btn-outline-secondary" onClick={()=>this.sendConfirmation()} disabled={this.state.sending}>Send Confirmation Link Again</buttonn>
                                    </div>
                                </>
                            )}
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}