import React from 'react';
import { Link } from 'react-router-dom';
import {toast} from 'react-toastify';

//import AccountNav from 'components/account/AccountNav';
//import MyModal from 'components/MyModal';

export default class BetaQuestions extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: false,
            questions: [],
            answer: '',
            currentQuestion: this.props.currentQuestion || 0,
            rating: 0
        }
    }

    componentDidMount(){
        this.fetchBetaQuestions();
    }

    fetchBetaQuestions = () => {
        fetch('https://app.notesforafter.com/operations/beta/fetchBetaQuestions/', {
            credentials: 'include'
        })
        .then((response)=>response.json())
        .then((data)=>{
            if(data.status==='success'){
                this.setState({loaded: true, questions: data.questions});
            }else{
                if(data.extra==='logged_out'){
                    localStorage.removeItem('token');
                    window.location.reload();
                }else{
                    toast.error(data.message);
                }
            }
        })
        .catch(error=>console.log(error));
    }

    submitAnswer = (currentQuestion) => {
        if(this.state.answer.length<10){
            toast.error('Please provide a complete answer for this section before submitting!');
            return;
        }else{
            if(this.state.rating===0){
                toast.error('Please select a Rating for this section before submitting!');
                return;
            }else{
                
                fetch('https://app.notesforafter.com/operations/beta/answerBetaQuestion/', {
                    method: "POST",
                    credentials: 'include',
                    body: JSON.stringify({ 
                        question_id: this.state.questions[currentQuestion].id,
                        answer: this.state.answer,
                        rating: this.state.rating
                    })
                })
                .then((response)=>response.json())
                .then((data)=>{
                    if(data.status!=='success'){
                        if(data.extra==='logged_out'){
                            localStorage.removeItem('token');
                            window.location.reload();
                        }else{
                            toast.error(data.message);
                        }
                    }else{
                        toast.success('Thank you! We appreciate your feedback!');
                        this.fetchBetaQuestions();
                    }
                })
                .catch(error=>console.log(error));
                
            }
        }
    }

    switchQuestion = (index) => {
        this.setState({currentQuestion: index})
    }
    
    render(){
        const currentQuestion = this.state.currentQuestion; //currentQuestion is the index
        const thisQuestion = this.state.questions[currentQuestion];
        return(

            <div className="row">
                <div className="col-sm-5" style={{marginBottom: '10px'}}>
                    <div className="card">
                        <div className="card-body">  
                            {this.props.screensize!=='small' ? (
                                <div>
                                    {this.state.loaded && (
                                        <>
                                            {this.state.questions.map((question, i)=>{
                                                let classes = i===currentQuestion ? 'question-menu-item active' : 'question-menu-item';
                                                return (
                                                    <button key={i} onClick={()=>this.switchQuestion(i)} className={classes}>
                                                        <span style={{fontSize: '22px'}}>
                                                            {question.done==='yes' ? (
                                                                <>
                                                                    <i className="fa fa-check"></i> {question.category}
                                                                </> 
                                                            ):(
                                                                <>
                                                                    {question.category}
                                                                </>
                                                            )}
                                                        </span>
                                                        <br />
                                                        Earn {question.worth_mb}mb of permanent storage
                                                    </button>
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                            ):(
                                <div>
                                    <select className="form-control" value={currentQuestion} onChange={(e)=>this.switchQuestion(e.target.value)}>
                                        {this.state.questions.map((question, i)=>{
                                            return (
                                                <option key={i} value={i}>
                                                    {question.category} - Get {question.worth_mb}mb
                                                </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            )}
                            
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    {this.props.screensize!=='small' && (
                        <div className="alert alert-info" style={{margin: '10px auto'}}>
                            Thank you for helping us improve NotesForAfter! You will receive your free extra storage when you complete each question. Be sure to answer thoughtfully and thoroughly! Low-effort/low-quality answers will result in the awarded storage being removed upon review.
                        </div>
                    )}
                       
                        <div className="card" style={{borderTop: 'none'}}>
                            <div className="card-body">
                                {!this.state.loaded ? (
                                    <div><i className="fa fa-spinner fa-spin"></i> Loading Beta Question...</div>
                                ):(
                                    <>
                                        {thisQuestion.done==='no' ? (
                                            <>
                                                <h4 style={{marginBottom: '25px'}}>{thisQuestion.category}</h4>
                                                <div style={{margin: '-25px auto 15px', fontSize: 14}}>
                                                    Earn <strong>{thisQuestion.worth_mb}mb</strong> of permanent storage for answering this!
                                                </div>
                                                <div>
                                                    {thisQuestion.description}
                                                    {thisQuestion.available==='no' && thisQuestion.category==='Gatekeepers' && (
                                                        <div style={{margin: '10px auto'}}><Link to="/account/gatekeepers">Head to Gatekeepers</Link></div>
                                                    )}
                                                    {thisQuestion.available==='no' && thisQuestion.category==='Check In Sequence' && (
                                                        <div style={{margin: '10px auto'}}><Link to="/account/checkin/">Head to Check In Sequence</Link></div>
                                                    )}
                                                    {thisQuestion.available==='no' && thisQuestion.category==='Vaults and Files' && (
                                                        <div style={{margin: '10px auto'}}><Link to="/account/files/">Head to Vaults</Link></div>
                                                    )}
                                                </div>
                                                {thisQuestion.available==='yes' && (
                                                    <div style={{marginTop: 15}}>
                                                        <textarea style={{height: 200}} className="form-control" onChange={(e)=>this.setState({answer: e.target.value})} value={this.state.answer} placeholder="Enter your answer here"></textarea>
                                                        <div style={{height: 10}}></div>
                                                        {thisQuestion.category} Rating: <select className="form-control" style={{display: 'inline', width: 'auto'}} value={this.state.rating} onChange={(e)=>this.setState({rating: e.target.value})}>
                                                            <option value={0}>Choose</option>
                                                            <option value={1}>1 *</option>
                                                            <option value={2}>2 **</option>
                                                            <option value={3}>3 ***</option>
                                                            <option value={4}>4 ****</option>
                                                            <option value={5}>5 *****</option>
                                                        </select>
                                                        <div style={{height: 10}}></div>
                                                        <button onClick={()=>this.submitAnswer(currentQuestion)} style={{marginTop: 10}} className="btn btn-outline-secondary">Submit Answer</button>
                                                    </div>
                                                )}
                                            </>
                                        ):(
                                            <>
                                                <h4 style={{marginBottom: '25px'}}>{thisQuestion.category}</h4>
                                                <div>
                                                    Thank you! You earned <strong>{thisQuestion.worth_mb}mb</strong> of permanent storage for completing this section!
                                                </div>
                                            </>
                                        )}
                                        
                                    </>
                                )}
                            </div>
                        </div>
                    
                </div>

            </div>

            
        )
    }

}