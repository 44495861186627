import React from 'react';
import SanitizedHTML from 'react-sanitized-html';

import MyModal from './MyModal';


export default class Prompter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hasPrompt: false,
            prompt: {}
        }
    }

    componentDidMount(){
        this.getNextPrompt();
    }

    getNextPrompt = () => {
        fetch('https://app.notesforafter.com/operations/user/getNextPrompt/', {
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==='success'){
                if(data.hasPrompt==='yes'){
                    this.setState({prompt: data.prompt, hasPrompt: true});
                }
            }
        })
        .catch((error)=>{
            console.log(error);
        })    
    }
    onCancelAction = () => {
        fetch('https://app.notesforafter.com/operations/user/dismissPrompt/', {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ 
                id: this.state.prompt.id
            })
        })
    }

    render(){
        if(!this.state.hasPrompt){
            return (<div></div>)
        }

        return (
            <MyModal 
                autoOpen 
                modalHeaderText={this.state.prompt.title} 
                onCloseModal={()=>this.setState({hasPrompt: false})}
                hasConfirm
                hasCancel
                onCancel={()=>this.onCancelAction()}
                onConfirm={()=>this.setState({hasPrompt: false})}
                confirmButtonText={this.state.prompt.okay_text}
                cancelButtonText={this.state.prompt.dismiss_text}
                >
                    <SanitizedHTML  
                        allowedTags={ [ 'h5', 'h4', 'h3', 'h2', 'h1', 'a', 'li', 'b', 'i', 'strong', 'br', 'div', 'img', 'p']} 
                        allowedAttributes={ { a: [ 'href', 'name', 'target' ], img: [ 'src' ]}}
                        selfClosing={ [ 'img', 'br']}
                        html={this.state.prompt.content}
                    />
            </MyModal>
        )
    }
}