import React from 'react';
import {toast} from 'react-toastify';

export default class LogOut extends React.Component {
    
    componentDidMount(){
        localStorage.removeItem('token');
        fetch('https://app.notesforafter.com/operations/user/logout/', {
                credentials: 'include'
            })
            .then((response)=>response.json())
            .then((data)=>{
                if(data.status==='success'){
                    window.location.href='https://app.notesforafter.com/';
                }else{
                    toast.error(data.message);
                }
            })
            .catch(error=>console.log(error));
        
    }

    render(){
        return(
            <div className="card" style={{borderTop: 'none'}}>
                <div className="card-body">
                    Logging Out...
                </div>
            </div>
        )
    }
}

